/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Days {
    monday: string;
    tuesday: string;
    wednesday: string;
    thursday: string;
    friday: string;
    saturday: string;
}

export interface Schedule {
    userName: string;
    userId: number;
    id: number;
    days: Days;
}

export const UserScheduleBuilder = (): Schedule[] => [
    {
        userName: '',
        userId: 0,
        id: 0,
        days: {
            monday: '',
            tuesday: '',
            wednesday: '',
            thursday: '',
            friday: '',
            saturday: '',
        },
    },
];

interface UserScheduleFromBack {
    concatNomPrenom: string;
    fk_livreur: number;
    id: number;
    jeudi: string;
    lundi: string;
    mardi: string;
    mercredi: string;
    samedi: string;
    vendredi: string;
}
export const FormatUserSchedule = ({ data }: any): Schedule[] =>
    data.map(
        (data: UserScheduleFromBack): Schedule => ({
            userName: data.concatNomPrenom,
            userId: data.fk_livreur,
            id: data.id,
            days: {
                monday: data.lundi,
                tuesday: data.mardi,
                wednesday: data.mercredi,
                thursday: data.jeudi,
                friday: data.vendredi,
                saturday: data.samedi,
            },
        }),
    );

interface ReFormatedSchedule {
    fk_livreur?: number;
    id: number;
    jeudi: string;
    lundi: string;
    mardi: string;
    mercredi: string;
    samedi: string;
    vendredi: string;
}

export const ReFormatSchedule = (data: any) => {
    const schedule: ReFormatedSchedule = {
        id: data?.id ? data.id : null,
        lundi: data.days.monday,
        mardi: data.days.tuesday,
        mercredi: data.days.wednesday,
        jeudi: data.days.thursday,
        vendredi: data.days.friday,
        samedi: data.days.saturday,
    };

    if (data.userId) schedule.fk_livreur = +data.userId;

    return schedule;
};

export const FormatOptionSchedule = ({ data }: any) =>
    data.map((data: UserScheduleFromBack) => ({
        label: data.concatNomPrenom,
        value: data.id,
    }));

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DocumentsListProps {
    id: string;
    documentNumber: string;
    createAt: string;
    statusId: string;
    statusDate: string;
    statusLabel: string;
    color: string;
    tiersSource_id: string;
    tour_id: string;
    tour_label: string;
    tiersDest_id: string;
    countPackage: string;
    tiersSourceName: string;
    tiersDestName: string;
    fk_delivery: string;
    deliveryman_name: string;
    [key: string]: string;
}

export const DocumentsListBuilder = (values: Partial<DocumentsListProps> = {}): DocumentsListProps[] => [
    {
        id: '',
        documentNumber: '',
        createAt: '',
        statusId: '',
        statusDate: '',
        statusLabel: '',
        color: '',
        tiersSource_id: '',
        tour_id: '',
        tour_label: '',
        tiersDest_id: '',
        countPackage: '',
        tiersSourceName: '',
        tiersDestName: '',
        fk_delivery: '',
        deliveryman_name: '',
        ...values,
    },
];

export const FormatDocumentsList = ({ data }: any): DocumentsListProps[] => {
    if (data === undefined || data.length === 0) return [];
    const array = data.map(
        (data: any): DocumentsListProps => {
            return {
                statusLabel: data.Libelle_fk_etat,
                statusId: data.id_etatDocument,
                tiersSource_id: data.fk_tiersSource,
                tiersSourceName: data.raisonSocialeTiersSource,
                tiersDest_id: data.fk_tiersDestination,
                tiersDestName: data.raisonSocialeTiersDestination,
                createAt: data.lastHistoryEtat,
                countPackage: data.countColis,
                documentNumber: data.numero_document,
                id: data.id_document,
                statusDate: data.date_etatDocument,
                color: data.couleur,
                tour_id: data.idTournee,
                tour_label: data.libelleTournee,
                fk_delivery: data.idLivraison,
                deliveryman_name: data.nomLivreur,
            };
        },
    );

    return array;
};

export default DocumentsListProps;

import React, { useEffect, useState } from 'react';

import { colisColumns } from './Cells';
import DatePicker from 'components/molecules/Inputs/DatePicker';
import Table from 'components/organisms/Table/ReactTable/AsyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { APItoFront, BackAllParams, column, PARAMS } from 'utils/CreateQueries';
import DocumentsListProps, { FormatDocumentsList } from 'models/DocumentsListBuilder';
import useOptions from 'utils/Hook/GetOptions';
import { useLocation, useSearchParams } from 'react-router-dom';
import DropdownMultipleSelect from 'components/molecules/MultiSelect';
import { formatDate } from '@fullcalendar/react';
import { QueryToState, QueryToString } from 'utils/CreateQueries/query';
import { getPreviousDay } from 'utils/FormatDate';
import Loading from 'components/organisms/Modal/Loading';
import Button from 'components/atoms/Buttons';

const DocumentsList = () => {
    const [filter, setFilter] = useState<PARAMS>(params);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [arrayLoading, setArrayLoading] = useState<boolean>(true);
    const [documentList, setDocumentList] = useState<DocumentsListProps[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [pageCount, setPageCount] = useState<number>(0);
    const { companySourceOptions, statusOptions: statusOption } = useOptions({
        getCompanies: true,
        getStatus: 'document',
    });
    const [, setSearchParams] = useSearchParams();
    const search = useLocation().search;

    const UpdateFromQuery = () => {
        const newQ = QueryToState(search);
        const localColumn: string = newQ.columnToOrder;
        const filterState = {
            pageIndex: newQ.numPage ? +newQ.numPage : +filter.pageIndex,
            pageSize: newQ.nbrElements ? +newQ.nbrElements : +filter.pageSize,
            sortBy: [{ id: APItoFront[localColumn], desc: !newQ.isAscendingOrder }],
            startDate: newQ.startDate ? newQ.startDate : filter.startDate,
            endDate: newQ.endDate ? newQ.endDate : filter.endDate,
            status: [],
            tiers: [],
            globalFilter: newQ.search ? newQ.search : filter.globalFilter,
        };

        if (newQ.fk_etat) {
            const fkStatus = newQ.fk_etat.map((elem: string[]) => statusOption.find((e) => +e.value === +elem));
            filterState.status = fkStatus;
        }

        if (newQ.fk_tiersSource) {
            const fkTiersSource = newQ.fk_tiersSource.map((elem: string[]) =>
                companySourceOptions.find((e) => +e.value === +elem),
            );
            filterState.tiers = fkTiersSource;
        }
        setFilter(filterState);
        GetDocument(search);
    };

    useEffect(() => {
        if (statusOption.length > 0 && pageLoading) {
            UpdateFromQuery();
        }
    }, [statusOption]);

    const GetDocument = async (queries: string) => {
        const { status, nbTotalRows, ...response } = await GET(API.DOCUMENTS_LIST + queries);
        if (!status) return;
        setDocumentList(FormatDocumentsList(response));
        setTotalRows(nbTotalRows);
        setPageCount(Math.round(nbTotalRows / filter.pageSize));
        setPageLoading(false);
        setArrayLoading(false);
    };

    const StateToQuery = (filter: any) => {
        setArrayLoading(true);
        const BackQuery: BackAllParams = {
            nbrElements: filter.pageSize,
            numPage: filter.pageIndex,
            search: !filter.globalFilter ? '' : filter.globalFilter,
            isAscendingOrder: filter.sortBy[0].desc ? 0 : 1,
            columnToOrder: column[filter.sortBy[0].id],
            fk_etat: filter.status,
            fk_tiersSource: filter.tiers,
            endDate: filter.endDate,
            startDate: filter.startDate,
        };
        setSearchParams(QueryToString(BackQuery));
        GetDocument(QueryToString(BackQuery));
    };

    const SETFILTER = (newParams: any) => {
        const includeUpdateList = ['pageSize', 'sortBy', 'pageIndex'];
        if (includeUpdateList.includes(Object.keys(newParams)[0])) {
            StateToQuery({ ...filter, ...newParams });
        }
        setFilter((curr: any) => ({
            ...curr,
            ...newParams,
            pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0,
        }));
    };

    if (pageLoading) return <Loading />;
    return (
        <>
            <section className="mx-10 my-5">
                <form id="DocumentSearch" className="flex w-full">
                    <DatePicker
                        onChange={(e: Date) =>
                            SETFILTER({ startDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-') })
                        }
                        selected={new Date(filter.startDate)}
                        label="Date de début"
                    />
                    <DatePicker
                        onChange={(e: Date) =>
                            SETFILTER({
                                endDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-'),
                            })
                        }
                        selected={new Date(filter.endDate)}
                        label="Date de fin"
                    />
                    <DropdownMultipleSelect
                        label="Sélection du tiers source"
                        onSelect={(value) =>
                            SETFILTER({
                                tiers: value,
                            })
                        }
                        defaultValue={filter.tiers}
                        items={companySourceOptions}
                    />
                    <DropdownMultipleSelect
                        label="Sélection de l'état"
                        onSelect={(value) =>
                            SETFILTER({
                                status: value,
                            })
                        }
                        defaultValue={filter.status}
                        items={statusOption}
                    />
                </form>
                <div className="flex justify-end my-2">
                    <Button
                        onClick={() => [StateToQuery({ ...filter, pageIndex: 0 }), setPageLoading(true)]}
                        variant="default"
                    >
                        Rechercher...
                    </Button>
                </div>
                <Table
                    columns={colisColumns}
                    data={documentList}
                    pageCount={pageCount}
                    loading={arrayLoading}
                    defaultParams={filter}
                    SETFILTER={SETFILTER}
                    nbTotalRows={totalRows}
                />
            </section>
        </>
    );
};

export default DocumentsList;

const params: PARAMS = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [{ id: 'documentNumber', desc: false }],
    startDate: formatDate(getPreviousDay(), { locale: 'fr' }).split('/').reverse().join('-'),
    endDate: formatDate(new Date(), { locale: 'fr' }).split('/').reverse().join('-'),
    status: [],
    tiers: [],
    globalFilter: '',
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface FilesHistoryProps {
    tiersName: string;
    delivererName: string;
    date: string;
    fileName: string;
    tiersId: number;
    id: number;
    [key: string]: string | number;
}

export const FilesHistoryBuilder = (values: Partial<FilesHistoryProps> = {}): FilesHistoryProps[] => [
    {
        tiersName: '',
        delivererName: '',
        date: '',
        fileName: '',
        tiersId: 0,
        id: 0,
        ...values,
    },
];

export const FormatFilesHistory = ({ data }: any): FilesHistoryProps[] => {
    const array = data.map((data: any) => {
        return {
            tiersName: data.RaisonSocialeTiers,
            delivererName: data.concatNomPrenom,
            date: data.dateTraitement,
            fileName: data.filename,
            tiersId: data.fk_tiers,
            id: data.id,
        };
    });

    return array;
};

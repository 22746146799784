import { Link } from 'react-router-dom';
import { BsArrowRepeat } from 'react-icons/bs';
import { VictoryPie, VictoryTheme } from 'victory';
import React, { useState, useEffect, Fragment, useContext } from 'react';

import HistogramModal from '../../components/organisms/Modal/Histogram';
import Button from 'components/atoms/Buttons';
import Table from 'components/organisms/Table/ReactTable';

import classNames from 'classnames';
import API from 'constants/API';
import { GET, POST } from 'utils/Fetch';
import Routes from 'constants/Routes';
import FormatDate from 'utils/FormatDate';
import { queries } from 'utils/CreateQueries/StackStatistic';
import { NotificationContext } from 'contexts/Notifications';
import { formatHistory, HistoryProps, homeListBuilder } from 'models/HistoryBuilder';
import { FormatTiersStats, StatisticBuilder, TiersStatsProps } from 'models/StatisticBarBuilder';
import { FormatPackageStats, PackageStatsBuilder, PackageStatsProps } from 'models/StatisticPieBuilder';
import Loading from 'components/organisms/Modal/Loading';
import { QueryStringFor } from 'utils/CreateQueries/query';
import { useAuthenticateContext } from 'contexts/Authentication';

const HomePage = () => {
    const { authentication } = useAuthenticateContext();
    const { dispatch } = useContext(NotificationContext);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<PackageStatsProps[]>(PackageStatsBuilder());
    const [historyList, setHistoryList] = useState<HistoryProps[]>(homeListBuilder());
    const [HistogramInfo, setHistogramInfo] = useState<TiersStatsProps[]>(StatisticBuilder());
    const [waitingFile, setwaitingFile] = useState<string[]>([]);

    const GetHistory = async () => {
        const response = await GET(API.STATS_DOCUMENTS_COLIS);
        const test: any[] = [];
        for (let index = 0; index < response.data.length; index++) {
            const i = test.findIndex((e) => e.id === response.data[index].id);
            if (i === -1) test.push(response.data[index]);
            else {
                if (test[i].counterColis === '0') test[i].counterColis = response.data[index].counterColis;
                if (test[i].counterLivraison === '0') test[i].counterLivraison = response.data[index].counterLivraison;
            }
        }
        response.data = test;
        setHistoryList(formatHistory(response));
    };

    const GetPackageStats = async () => {
        const { status, message, ...response } = await GET(
            `${API.PACKAGE_STATS_PIE}?startDate=${new Date().getFullYear()}-01-01&endDate=${FormatDate(new Date())}`,
        );
        if (!status) dispatch({ status, message });
        setStats(FormatPackageStats(response));
        setLoading(false);
    };

    const GetAllTiersStats = async () => {
        const { data } = await GET(API.OPTIONS_COMPANIES_SOURCE);
        const StatsQueries = queries({
            isDistributor: 1,
            excel: '0',
            tsId: data,
        });
        const { status, ...response } = await GET(`${API.PACKAGE_STATS_BAR}${StatsQueries}`);
        if (!status) return;
        setHistogramInfo(FormatTiersStats(response));
    };

    useEffect(() => {
        GetHistory();
        GetPackageStats();
        GetWaitingFile();
        GetAllTiersStats();
    }, []);

    const SendToDelivery = async (idCentreLogistique: number) => {
        setLoading(true);
        const { status, message } = await POST(`${API.MISE_EN_LIVRAISONS}`, { idCentreLogistique: idCentreLogistique });
        setLoading(false);
        return dispatch({ status, message });
        if (!status) {
            setLoading(false);
            return dispatch({ status, message });
            // setTimeout(() => {
            //     SendToDelivery();
            // }, 1000 * 60 * 10);
        } else {
            setLoading(false);
        }
    };

    const GetWaitingFile = async () => {
        const { status, message, data } = await GET(API.WAITING_FILES);
        if (!status) return dispatch({ status, message });
        if (data !== '') setwaitingFile(data.data.split(','));
    };

    const StartFileProcesss = async () => {
        const { status, message } = await POST(API.START_FILE_PROCESS);
        if (!status) return dispatch({ status, message });
        setwaitingFile([]);
    };
    return (
        <>
            {loading && <Loading />}
            <section className="relative flex flex-col items-center">
                <div className="flex">
                    {authentication.centreLogistique.map((row: any, i) => (
                        <Button key={i} variant="primary" className="mt-5" onClick={() => SendToDelivery(row.idCL)}>
                            {loading && (
                                <svg
                                    className="animate-spin h-5 w-5 mr-3 rounded-full border-r-2 border-t-2 border-white z-50"
                                    viewBox="0 0 24 24"
                                />
                            )}
                            Mise en Livraison ( {row.libelleCL} )
                        </Button>
                    ))}
                </div>

                <div className="container flex m-auto flex-col items-center lg:flex-row lg:items-start">
                    <div className="w-full mx-5 lg:my-5 pt-2 lg:w-1/2 shadow-md bg-white">
                        <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                            <h2 className="text-2xl text-gray-100 my-2">Stat colis/Documents/Livraison</h2>
                            {/* <button
                                type="button"
                                onClick={() => GetHistory()}
                                className="flex items-center cursor-pointer justify-center w-8 h-8 bg-blue-500 hover:bg-blue-800 text-white text-2xl  border border-transparent focus:outline-none focus:ring-2 focus:ring-blue-800 focus:border-transparent "
                            >
                                <BsArrowRepeat />
                            </button> */}
                        </div>
                        <Table
                            columns={columns}
                            data={historyList}
                            getRowProps={(row) => ({
                                className: classNames('text-purple-800', {
                                    'bg-purple-200': row.original.status_color === 'secondary',
                                    'bg-blue-200': row.original.status_color === 'info',
                                    'bg-green-200': row.original.status_color === 'success',
                                    'bg-red-200': row.original.status_color === 'error',
                                }),
                            })}
                        />
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2">
                        {waitingFile.length !== 0 && (
                            <div className="flex flex-col w-full lg:mx-2 lg:my-5 py-2">
                                <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                                    <h2 className="text-2xl text-gray-100 my-2">Fichier(s) en attente</h2>
                                    <Button variant="primary" onClick={() => StartFileProcesss()} bold>
                                        Traiter les fichiers
                                    </Button>
                                </div>
                                {waitingFile.map((item) => (
                                    <p key={item}>{item}</p>
                                ))}
                            </div>
                        )}
                        <div>
                            <div className="flex flex-col w-full lg:mx-2 lg:my-5 py-2">
                                <div className="flex justify-between items-center border bg-purple-700  border-purple-700 px-2 mb-1 rounded-t-md">
                                    <h2 className="text-2xl text-gray-100 my-2">Statistique</h2>
                                    <Button variant="primary" onClick={() => setOpenModal(true)} bold>
                                        Histogramme
                                    </Button>
                                </div>
                                <div className="border shadow-md p-2 bg-white">
                                    <VictoryPie
                                        theme={VictoryTheme.material}
                                        height={200}
                                        colorScale={[
                                            '#003f5c',
                                            '#2f4b7c',
                                            '#665191',
                                            '#a05195',
                                            '#d45087',
                                            '#f95d6a',
                                            '#ff7c43',
                                            '#ffa600',
                                        ]}
                                        data={stats}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HistogramModal open={openModal} setOpen={setOpenModal} HistogramInfo={HistogramInfo} />
        </>
    );
};

const columns = [
    {
        Header: 'État colis',
        accessor: 'libelleColis',
        Cell: (props: { value: string; row: { original: HistoryProps } }) => (
            <Fragment>
                {props.row.original.status_forType.split(',').includes('colis') && (
                    <Link to={Routes.PACKAGES + QueryStringFor('packageList', { fk_etat: props.row.original.fk_etat })}>
                        <span className="text-sm w-5/6 text-purple-900 hover:underline">
                            {props.row.original.status_label}
                        </span>
                    </Link>
                )}
            </Fragment>
        ),
    },
    {
        Header: '',
        accessor: 'counterColis',
        Cell: (props: { row: { original: HistoryProps } }) => (
            <span>
                {props.row.original.status_forType.split(',').includes('colis') ? props.row.original.counterColis : ''}
            </span>
        ),
    },
    {
        Header: 'État document',
        accessor: 'libelleDocuments',
        Cell: (props: { value: string; row: { original: HistoryProps } }) => (
            <Fragment>
                {props.row.original.status_forType.split(',').includes('document') && (
                    <Link to={Routes.DOCUMENTS + QueryStringFor('documents', { fk_etat: props.row.original.fk_etat })}>
                        <span className="text-sm w-5/6 text-purple-900 hover:underline">
                            {props.row.original.status_label}
                        </span>
                    </Link>
                )}
            </Fragment>
        ),
    },
    {
        Header: '',
        accessor: 'counterDocument',
        Cell: (props: { row: { original: HistoryProps } }) => (
            <span>
                {props.row.original.status_forType.split(',').includes('document')
                    ? props.row.original.counterDocument
                    : ''}
            </span>
        ),
    },

    {
        Header: 'État livraison',
        accessor: 'libelleDocument',
        Cell: (props: { value: string; row: { original: HistoryProps } }) => (
            <Fragment>
                {props.row.original.status_forType.split(',').includes('livraison') && (
                    <Link to={Routes.DELIVERY + QueryStringFor('delivery', { fk_etat: props.row.original.fk_etat })}>
                        <span className="text-sm w-5/6 text-purple-900 hover:underline">
                            {props.row.original.status_label}
                        </span>
                    </Link>
                )}
            </Fragment>
        ),
    },
    {
        Header: '',
        accessor: 'counterLivraison',
        Cell: (props: { row: { original: HistoryProps } }) => (
            <span>
                {props.row.original.status_forType.split(',').includes('livraison')
                    ? props.row.original.counterLivraison
                    : ''}
            </span>
        ),
    },
];

export default HomePage;

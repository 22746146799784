import React, { FunctionComponent, Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';

import { HistoryKey } from 'models/CompanyBuilder/CompaniesList';
import Routes from 'constants/Routes';
import classNames from 'classnames';

interface DestinatairesSourcesProps {
    id: string;
}

const HistoriqueCle: FunctionComponent<DestinatairesSourcesProps> = ({ id }) => {
    const [historyKey, setHistoryKey] = useState<HistoryKey[]>([]);

    const GetHistoryKey = async () => {
        const response = await GET(`${API.HISTORY_KEYS}/${id}`);
        if (!response.status) return;
        setHistoryKey(response.data);
    };

    useEffect(() => {
        GetHistoryKey();
    }, []);

    const status = { 15: 'Rangé', 16: 'En chargement', 17: 'En livraison', 18: 'Perdu' };

    const columns = [
        {
            Header: 'Date',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <p>{dayjs(props.row.original.date).format('DD/MM/YY HH:mm')}</p>
                </Fragment>
            ),
        },
        {
            Header: 'Statue',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <p
                        className={classNames('text-sm  p-1', {
                            'text-green-500': props.row.original.couleur === 'success',
                            'text-blue-500': props.row.original.couleur === 'info',
                            'text-red-500': props.row.original.couleur === 'error',
                        })}
                    >
                        {status[props.row.original.fk_etat as keyof typeof status]}
                    </p>
                </Fragment>
            ),
        },
        {
            Header: 'Numero de clé',
            accessor: 'numero',
        },
        {
            Header: 'Dernier utilisateur',
            accessor: 'nomPrenom',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.USER}/${props.row.original.idUtilisateur}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.nomPrenom}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Tournée',
            accessor: 'Libelle',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.TOUR}/${props.row.original.idTournee}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.Libelle}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Derniere Livraison',
            Cell: (props: { row: { original: HistoryKey } }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <Link to={`${Routes.DELIVERY}/${props.row.original.fk_livraison}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">{props.row.original.fk_livraison}</p>
                        </Link>
                    </span>
                </Fragment>
            ),
        },
    ];

    return (
        <section className="mx-10 my-5 w-[100%]">
            {historyKey.length !== 0 ? (
                <Table columns={columns} data={historyKey} />
            ) : (
                <p>Pas de données dans la base de données</p>
            )}
        </section>
    );
};

export default HistoriqueCle;

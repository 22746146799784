/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import Icon from 'components/atoms/Icon';
import React, { FunctionComponent } from 'react';
import { FaLongArrowAltDown, FaLongArrowAltUp } from 'react-icons/fa';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce, Column, Row } from 'react-table';

interface TableProps<P> {
    columns: Column<any>[];
    data: P[];
    sortBy?: string;
    getRowProps?: (arg0: Row<any>) => {};
    getHeaderProps?: (arg0: Row<any>) => {};
    getColumnProps?: (arg0: Row<any>) => {};
}

const defaultPropGetter = () => ({});

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }: any) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Recherche:
            <input
                className="px-2 mx-2 border-b-2 border-gray-400 outline-none focus:border-purple-800 focus:shadow-xl"
                value={value || ''}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} éléments...`}
            />
        </span>
    );
};

const Table: FunctionComponent<TableProps<Record<string, any>>> = ({
    columns,
    data,
    sortBy = '',
    getRowProps = defaultPropGetter,
    getHeaderProps = defaultPropGetter,
    getColumnProps = defaultPropGetter,
}) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                sortBy: [
                    {
                        id: sortBy,
                        desc: false,
                    },
                ],
            },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    return (
        <>
            <div className="flex justify-between mt-5 mb-2">
                <span>
                    Afficher
                    <select
                        value={pageSize}
                        className="border border-gray-400 rounded-md w-16 p-1 outline-none mx-2"
                        onChange={(e) => {
                            setPageSize(Number(e.target.value));
                        }}
                    >
                        {[10, 25, 50, 100].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                        ))}
                    </select>
                    éléments
                </span>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>

            <table {...getTableProps()} className="min-w-full table-fixed">
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, i) => (
                                <th
                                    {...column.getHeaderProps([
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        getColumnProps(column),
                                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        // @ts-ignore
                                        getHeaderProps(column),
                                        {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            className: column.className,
                                        },
                                        column.getSortByToggleProps(),
                                    ])}
                                    key={i}
                                >
                                    <span className="flex items-center justify-between font-extralight text-gray-500">
                                        {column.render('Header')}
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <Icon Icon={FaLongArrowAltDown} />
                                            ) : (
                                                <Icon Icon={FaLongArrowAltUp} />
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                className={classNames('border-black hover:bg-gray-300', {
                                    '': index % 2 === 1,
                                    'bg-gray-100': index % 2 === 0,
                                })}
                                {...row.getRowProps(getRowProps(row))}
                                key={index}
                            >
                                {row.cells.map((cell, i) => {
                                    return (
                                        <td
                                            {...cell.getCellProps()}
                                            key={i}
                                            className={classNames('h-14 px-2 border text-sm max-w-sm')}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="flex justify-end w-full mt-2">
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}
                >
                    {'<<'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}
                >
                    {'<'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}
                >
                    {'>'}
                </button>
                <button
                    className="mx-1 outline-none relative inline-flex items-center px-2 py-1 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:border-purple-900 focus:text-purple-900"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}
                >
                    {'>>'}
                </button>
                <span className="flex items-center text-sm">
                    <span className="pr-2">Page</span>
                    <strong>
                        {pageIndex + 1} sur {pageOptions.length}
                    </strong>
                </span>
            </div>
        </>
    );
};

export default Table;

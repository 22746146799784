/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PackagesHistories {
    [key: string]: string;
    id: string;
    number: string;
    weights: string;
    date: string;
    label: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
    latitude: string;
    longitude: string;
}

export interface DocumentHistories {
    date: string;
    id: string;
    label: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
    delivery_id: string;
    deliveryMan_name: string;
    fk_etat: any;
    [key: string]: string;
}

export interface DocumentsProps {
    comment: string;
    tiersDestAddress: string;
    createAt: string;
    tiersDestId: string;
    tiersSourceId: string;
    documentNumber: string;
    tiersDestName: string;
    tiersSourceName: string;
    phoneNumberTiersDest: string;
    phoneNumberTiersDest2: string;
    packageHistories: PackagesHistories[];
    documentHistories: DocumentHistories[];
}

export const DocumentsBuilder = (values: Partial<DocumentsProps> = {}): DocumentsProps => ({
    comment: '',
    tiersDestAddress: '',
    createAt: '',
    tiersDestId: '',
    tiersSourceId: '',
    documentNumber: '',
    tiersDestName: '',
    tiersSourceName: '',
    phoneNumberTiersDest: '',
    phoneNumberTiersDest2: '',
    packageHistories: [],
    documentHistories: [],
    ...values,
});

export const FormatDocument = ({ data }: any): DocumentsProps => {
    const array = data.map((data: any) => {
        const _packageHistories = data.colisHistory.map(
            (data: any): PackagesHistories => {
                return {
                    id: data.id_colis,
                    number: data.numero_colis,
                    weights: data.poids,
                    date: data.lastHistoryEtat,
                    label: data.Libelle_fk_etat,
                    color: data.couleur,
                    latitude: data.latitude,
                    longitude: data.longitude,
                };
            },
        );

        const _documentHistories = data.documentHistory.map(
            (data: any): DocumentHistories => {
                return {
                    date: data.lastHistoryEtat,
                    id: data.fk_livraison,
                    label: data.Libelle_fk_etat,
                    color: data.couleur,
                    delivery_id: data.fk_livraison,
                    fk_etat: data.fk_etat,
                    deliveryMan_name: data.concatNomPrenomLivreur,
                };
            },
        );

        const _document = {
            comment: data.commentaire,
            tiersDestAddress: data.concatTiersDestinationAdress,
            createAt: data.dateCreation,
            tiersDestId: data.fk_tiersDestination,
            tiersSourceId: data.fk_tiersSource,
            documentNumber: data.numero_document,
            tiersDestName: data.raisonSocialeTiersDestination,
            tiersSourceName: data.raisonSocialeTiersSource,
            phoneNumberTiersDest: data.telephone1TiersDestination,
            phoneNumberTiersDest2: data.telephone2TiersDestination,
            packageHistories: _packageHistories,
            documentHistories: _documentHistories,
        };

        return _document;
    });

    return array[0];
};

export default DocumentsProps;

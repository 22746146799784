import classNames from 'classnames';
import ReactSelect from 'react-select';
import { IconType } from 'react-icons';
import React, { FunctionComponent } from 'react';

import Icon from 'components/atoms/Icon';
import { Options } from 'utils/CreateQueries';

interface SelectProps {
    option: Options[];
    name: string;
    className?: string;
    isClearable?: boolean;
    isMulti?: boolean;
    label?: string;
    icon?: IconType;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange: (arg0: any) => void;
    defaultValue?: Options | Options[];
}

const Select: FunctionComponent<SelectProps> = ({
    label,
    isClearable = true,
    isMulti = true,
    icon,
    className,
    option,
    onChange,
    defaultValue,
}) => {
    return (
        <div className={classNames('flex flex-col w-full px-2 mb-2', className)}>
            {label && (
                <label className="flex flex-row text-sm text-gray-400 max-h-full">
                    {icon && <Icon Icon={icon} className="text-2xl mr-2" />}
                    {label}
                </label>
            )}
            <ReactSelect
                className="border-purple-800"
                onChange={onChange}
                isClearable={isClearable}
                isMulti={isMulti}
                options={option}
                defaultValue={defaultValue}
                styles={
                    {
                        // control: (base: any, state: any) => ({
                        //     ...base,
                        //     borderColor: state.isFocused ? '#5B21B6' : base.borderColor,
                        //     '&:hover': {
                        //         borderColor: state.isFocused ? '#5B21B6' : base.borderColor,
                        //     },
                        // }),
                    }
                }
            />
        </div>
    );
};

export default Select;

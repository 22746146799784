import { TiersStatsProps } from 'models/StatisticBarBuilder';
import React, { FunctionComponent } from 'react';
import {
    VictoryBar,
    VictoryChart,
    VictoryAxis,
    VictoryTheme,
    VictoryStack,
    VictoryTooltip,
    VictoryLegend,
    VictoryLabel,
} from 'victory';

interface HistogramProps {
    data: TiersStatsProps[];
}

const colorScale = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];

const Histogram: FunctionComponent<HistogramProps> = ({ data }) => {
    return (
        <VictoryChart domainPadding={20} height={205} width={600} theme={VictoryTheme.material}>
            <VictoryLegend
                x={125}
                y={0}
                orientation="horizontal"
                gutter={15}
                colorScale={colorScale}
                itemsPerRow={4}
                symbolSpacer={5}
                data={data.map((data) => ({ name: data.tName }))}
                labelComponent={<VictoryLabel style={{ fontSize: 8 }} />}
            />
            <VictoryAxis
                dependentAxis
                style={{
                    axisLabel: { padding: 30, fontSize: 10 },
                    tickLabels: { fontSize: 5 },
                }}
            />
            <VictoryAxis
                style={{
                    axisLabel: { padding: 25, fontSize: 10 },
                    tickLabels: { fontSize: 5 },
                }}
            />
            <VictoryStack scale={{ x: 'linear', y: 'linear' }} colorScale={colorScale}>
                {data.map((data: TiersStatsProps, i: number) => (
                    <VictoryBar
                        labelComponent={<VictoryTooltip style={{ fontSize: 6 }} />}
                        key={i}
                        data={data.stats}
                        barWidth={15}
                    />
                ))}
            </VictoryStack>
        </VictoryChart>
    );
};

export default Histogram;

enum Routes {
    HOME = '/',

    LOGIN = '/login/',

    COMPANY = '/tiers',
    COMPANY_ID = '/tiers/:id',
    COMPANY_CLOSURE = '/tiers/fermeture',
    COMPANY_DESTINATAIRE = '/tiers/destinataire',
    COMPANY_KEY_HISTORY = '/tiers/status-des-cles',

    DOCUMENTS = '/documents',
    DOCUMENTS_ID = '/documents/:id',

    PACKAGES = '/colis',
    PACKAGES_ID = '/colis/:id',

    DELIVERY = '/feuille-de-route',
    DELIVERY_ID = '/feuille-de-route/:id',

    TOUR = '/tournee',
    TOUR_ID = '/tournee/:id',

    USERS = '/utilisateurs',
    USER = '/utilisateur',
    USER_ID = '/utilisateur/:id',
    USER_SCHEDULE = '/utilisateurs/horaire',
    USER_CALENDAR = '/utilisateurs/calendrier',

    MY_ACCOUNT = '/mon-compte',

    MAP = '/carte',

    FILES_HISTORY = '/fichiers',
    FILES_HISTORY_ID = '/fichiers/:id',
}

export default Routes;

import React, { Fragment, useEffect, useState } from 'react';
import { FaFileCsv } from 'react-icons/fa';
import { FaMapMarkedAlt } from 'react-icons/fa';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Buttons';
import Table from 'components/organisms/Table/ReactTable/SyncTable';
import Select from 'components/molecules/Inputs/Select';

import Routes from 'constants/Routes';
import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { ExportCSV } from 'utils/ExportCSV';
import { Destination } from 'models/CompanyBuilder/CompaniesList';
import { Link } from 'react-router-dom';

const DestinataireListPage = () => {
    const [listeDesSources, setListeDesSources] = useState<Destination[]>([]);
    const [listeSources, setListeSources] = useState<any[]>([]);
    const [source, setSource] = useState<string>('all');

    const GetTiersList = async () => {
        let response = await GET(API.LIST_TIERS_SOURCES);
        if (!response.status) return;
        response.data.unshift({ value: 'all', label: 'Toute les sources' });
        setListeSources(response.data);
        response = await GET(`${API.LIST_TIERS_DES_SOURCES}${source && `?type=source&id=${source}`}`);
        if (!response.status) return;
        setListeDesSources(response.data);
    };

    useEffect(() => {
        GetTiersList();
    }, [source]);

    const columns = [
        {
            Header: 'Identifiant',
            accessor: 'Identifiant',
            className: 'text-left border border-gray-200  px-2 w-1/12',
            Cell: (props: { row: { original: { Identifiant: string; fk_tiersDestination: string } } }) => (
                <Fragment>
                    <Link to={`${Routes.COMPANY}/${props.row.original.fk_tiersDestination}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.row.original.Identifiant}</p>
                    </Link>
                </Fragment>
            ),
        },
        {
            Header: 'Raison Social',
            accessor: 'RaisonSociale',
            className: 'text-left border border-gray-200  px-2  w-2/12',
            Cell: (props: { row: { original: { RaisonSociale: string; fk_tiersDestination: string } } }) => (
                <Fragment>
                    <Link to={`${Routes.COMPANY}/${props.row.original.fk_tiersDestination}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.row.original.RaisonSociale}</p>
                    </Link>
                </Fragment>
            ),
        },
        {
            Header: 'Tiers source',
            accessor: 'libelleSource',
            className: 'text-left border border-gray-200  px-2  w-2/12',
            Cell: (props: { row: { original: { libelleSource: string; fk_tiersSource: string } } }) => (
                <Fragment>
                    <Link to={`${Routes.COMPANY}/${props.row.original.fk_tiersSource}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.row.original.libelleSource}</p>
                    </Link>
                </Fragment>
            ),
        },
        {
            Header: 'Mail',
            accessor: 'email',
            Cell: (props: { value: string }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:${props.value}`}>
                            {props.value}
                        </a>
                    </span>
                </Fragment>
            ),
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Ville',
            accessor: 'adresseComplete',
            className: 'text-left border border-gray-200  px-2 w-3/12',
            Cell: (props: { value: string }) => (
                <Fragment>
                    <span className="flex justify-between">
                        <span>
                            <span className="text-sm pl-2">{props.value}</span>
                        </span>

                        <a
                            className="text-sm text-purple-900 hover:underline"
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${props.value}`}
                            rel="noreferrer"
                        >
                            <FaMapMarkedAlt className="text-lg" />
                        </a>
                    </span>
                </Fragment>
            ),
        },
        {
            Header: 'Commentaire',
            accessor: 'commentaire',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Horaire',
            accessor: 'openingHours',
            Cell: (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => (
                <Fragment>
                    {props.value &&
                        props.value.split(';').map((row, i) => (
                            <p key={i}>
                                {row}
                                <br />
                            </p>
                        ))}
                </Fragment>
            ),
            className: 'text-left border border-gray-200  px-2 w-2/12',
        },
    ];

    return (
        <section className="mx-10 my-5">
            <div className="flex items-center justify-between">
                <Select
                    className="w-[20%]"
                    label="Sélection de la source"
                    name="sources"
                    option={listeSources}
                    onChange={(e) => setSource(e.value)}
                    isMulti={false}
                    isClearable={false}
                />
                <Button onClick={() => ExportCSV(listeDesSources)}>
                    <Icon Icon={FaFileCsv} className="w-5" />
                    Exporter les destinations
                </Button>
            </div>

            <Table columns={columns} data={listeDesSources} />
        </section>
    );
};

export default DestinataireListPage;

/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';
import React, { FunctionComponent, MutableRefObject } from 'react';
import { useTable, Column, Row } from 'react-table';

interface TableProps<P extends Record<string, unknown>> {
    columns: Column<any>[];
    data: P[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    getRowProps?: (arg0: Row<any>) => {};
    ref?: MutableRefObject<any>;
}

const defaultPropGetter = () => ({});

const Table: FunctionComponent<TableProps<Record<string, unknown>>> = ({
    columns,
    data,
    getRowProps = defaultPropGetter,
    ref,
}) => {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data,
    });

    return (
        <table ref={ref} className="border border-gray-200 w-full bg-white" {...getTableProps()}>
            <thead>
                {headerGroups.map((headerGroup, index) => (
                    <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup.headers.map((column, i) => (
                            <th
                                className="text-left border border-gray-200 font-extralight text-gray-500 px-2"
                                {...column.getHeaderProps()}
                                key={i}
                            >
                                {column.render('Header')}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                        <tr
                            className={classNames('hover:bg-gray-300', {
                                '': index % 2 === 1,
                                'bg-gray-100': index % 2 === 0,
                            })}
                            {...row.getRowProps(getRowProps(row))}
                            key={index}
                        >
                            {row.cells.map((cell, i) => {
                                return (
                                    <td
                                        {...cell.getCellProps()}
                                        key={i}
                                        className={classNames('h-14 px-2 border text-sm')}
                                    >
                                        {cell.render('Cell')}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default Table;

export const RefTable = React.forwardRef((props: any, ref: any) => {
    return (
        <div ref={ref}>
            <style type="text/css" media="print">
                {'\
                    @page {size: A4 portrait; margin: 8mm 10mm 0 10mm !important;}\
                '}
            </style>
            <Table columns={props.columns} data={props.data} />
        </div>
    );
    RefTable.displayName = 'MyComponent';
});

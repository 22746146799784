import classnames from 'classnames';
import classNames from 'classnames';
import React, { ChangeEventHandler, FunctionComponent } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

interface SelectProps {
    name: string;
    label?: string;
    register?: React.LegacyRef<HTMLSelectElement>;
    errors?: DeepMap<any, FieldError>;
    onChange?: ChangeEventHandler<HTMLSelectElement> | undefined;
    messageError?: string;
    options: {
        label: string;
        value: number | string;
    }[];
    value?: string;
    className?: string;
    disabled?: boolean;
    inLine?: boolean;
    placeHolder?: string;
}
const Select: FunctionComponent<SelectProps> = ({
    name,
    label,
    register,
    options,
    errors,
    onChange,
    messageError,
    value,
    inLine = false,
    disabled = false,
    placeHolder = '',
}) => (
    <div
        className={classnames('flex p-1', {
            'flex-col': inLine === false,
            'flex-row items-center justify-between': inLine === true,
        })}
    >
        {label && (
            <label htmlFor={name} className="pb-1 flex flex-col text-sm text-gray-400 max-h-full">
                {label}
            </label>
        )}
        <select
            name={name}
            value={value}
            ref={register}
            onChange={onChange}
            className={classNames(
                {
                    'border-b bg-gray-300 text-gray-400 p-2 outline-none': disabled,
                },
                {
                    'border-b border-purple-800 bg-gray-100 p-2 outline-none': !disabled,
                },
            )}
            disabled={disabled}
        >
            <option value="">Choisir {placeHolder ?? '...'}</option>
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
        <span className="ml-2 text-red-600 font-bold">{errors && errors[name] && messageError}</span>
    </div>
);

export default Select;

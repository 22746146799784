import React, { FunctionComponent, Reducer, useReducer } from 'react';
import Totification from 'components/atoms/Notifications';
interface State {
    message: string;
    status: boolean;
}
interface NotificationProps {
    state: State;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: (v: any) => void;
}

export const NotificationContext = React.createContext<NotificationProps>({
    state: {
        message: '',
        status: false,
    },
    dispatch: () => {},
});

const reducer = (state: { message: null | string; status: boolean }, action: { status: boolean; message: string }) => {
    switch (action.status) {
        case true:
            return (state = { message: action.message, status: action.status });
        case false:
            return (state = { message: action.message, status: action.status });
        default:
            return (state = { message: null, status: false });
    }
};
const initialState = { message: null, status: false };

export const Notification: FunctionComponent = ({ children }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [state, dispatch] = useReducer<Reducer<any, any>>(reducer, initialState);

    return (
        <NotificationContext.Provider value={{ state, dispatch }}>
            <>
                <Totification message={state.message} status={state.status} />
                {children}
            </>
        </NotificationContext.Provider>
    );
};

import classNames from 'classnames';
import React, { FunctionComponent } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

interface LinkProps {
    to:
        | string
        | {
              pathname: string;
          };
    className?: string;
    target?: string;
    onClick?: () => void;
}

interface IconProps extends LinkProps {
    icon?: IconType;
}

const IconLink: FunctionComponent<IconProps> = ({ to, children, className, onClick, target }) => {
    return (
        <Link
            target={target}
            className={classNames('flex items-center outline-none', className)}
            onClick={onClick}
            to={to}
        >
            {children}
        </Link>
    );
};

export default IconLink;

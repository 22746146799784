import React, { FunctionComponent, useCallback } from 'react';
import update from 'immutability-helper';
import SimpleRow from './DragableRow';
import { useDrop } from 'react-dnd';
import { TourOrder } from 'models/TourBuilder';

interface Props {
    lists: TourOrder[];
    setList: React.Dispatch<React.SetStateAction<TourOrder[]>>;
    setUpdate: (list?: TourOrder[]) => Promise<void>;
}
const Row: FunctionComponent<Props> = ({ lists, setList, setUpdate }) => {
    const FindCard = useCallback(
        (id: string) => {
            const card = lists.filter((c) => `${c.order}` === id)[0];
            return {
                card,
                index: lists.indexOf(card),
            };
        },
        [lists],
    );

    const MoveCard = useCallback(
        (id: string, atIndex: number) => {
            const { card, index } = FindCard(id);
            setList(
                update(lists, {
                    $splice: [
                        [index, 1],
                        [atIndex, 0, card],
                    ],
                }),
            );
        },
        [FindCard, lists, setList],
    );
    const DeleteCard = (id: number) => {
        const newList = [];
        for (let index = 0; index < lists.length; index++) {
            if (index !== id) newList.push(lists[index]);
        }
        setUpdate(newList);
    };

    const [, drop] = useDrop(() => ({ accept: 'card' }));
    return (
        <div ref={drop} className="w-full">
            {lists.map((list: TourOrder) => {
                return (
                    <SimpleRow
                        key={list.order}
                        id={`${list.order}`}
                        moveCard={MoveCard}
                        findCard={FindCard}
                        setUpdate={setUpdate}
                        deleteCard={DeleteCard}
                        {...list}
                    />
                );
            })}
        </div>
    );
};

export default Row;

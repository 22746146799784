import React, { FunctionComponent, useContext } from 'react';

import Button from 'components/atoms/Buttons';

import { DELETE } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    url: string;
}

const Modal: FunctionComponent<ModalProps> = ({ open = false, setOpen, url }) => {
    const { dispatch } = useContext(NotificationContext);

    const DeleteTiers = async () => {
        const { status, message } = await DELETE(url);
        dispatch({ status, message });
        setOpen(!status);
    };

    return (
        <>
            {open ? (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-sm">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-xl font-semibold">Confirmer la suppression</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <p className="text-blueGray-400 text-lg leading-relaxed">
                                        Merci de confirmer la suppression
                                    </p>
                                    <p className="my-4 text-blueGray-500 text-lg leading-relaxed">Supprimer</p>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <Button onClick={() => setOpen(false)} variant="primary">
                                        Non
                                    </Button>
                                    <Button onClick={() => DeleteTiers()} variant="secondary">
                                        Supprimer
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            ) : null}
        </>
    );
};

export default Modal;

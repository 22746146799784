/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PackageHistories {
    label: string;
    createAt: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
    latitude: number;
    longitude: number;
    [key: string]: string | number;
}

export interface PackageProps {
    createAt: string;
    tiersDestId: number;
    tiersDestName: string;
    tiersSourceId: number;
    tiersSourceName: string;
    documentId: number;
    documentNumber: string;
    packageNumber: string;
    packageHistories: PackageHistories[];
}

export const PackageBuilder = (values: Partial<PackageProps> = {}): PackageProps => ({
    createAt: '',
    tiersDestId: 0,
    tiersDestName: '',
    tiersSourceId: 0,
    tiersSourceName: '',
    documentId: 0,
    documentNumber: '',
    packageNumber: '',
    packageHistories: [],

    ...values,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatPackage = ({ data }: any): any => {
    const array = data.map((data: any) => {
        const _packageHistories = data.history.map(
            (data: any): PackageHistories => {
                return {
                    label: data.Libelle,
                    createAt: data.lastHistoryEtat,
                    latitude: data.latitude,
                    longitude: data.longitude,
                    color: data.couleur,
                };
            },
        );

        return {
            createAt: data.dateCreation,
            tiersDestId: data.fk_tiersDestination,
            tiersDestName: data.raisonSocialeTiersDestination,
            tiersSourceId: data.fk_tiersSource,
            tiersSourceName: data.raisonSocialeTiersSource,
            documentId: data.fk_document,
            documentNumber: data.numero_document,
            packageNumber: data.numero_colis,
            packageHistories: _packageHistories,
        };
    });

    return array[0];
};

export default PackageProps;

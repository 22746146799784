import Button from 'components/atoms/Buttons';
import Calendar from 'components/atoms/Calendar';
import API from 'constants/API';
import { NotificationContext } from 'contexts/Notifications';
import { EmptyEvent, Events, FormatEvents, Absent, EmptyAbsent } from 'models/UsersBuilder/UsersAbsents';
import React, { useContext, useEffect, useState } from 'react';
import { GET } from 'utils/Fetch';
import AbsentModal from './absentModal';

const UsersCalendar = () => {
    const { dispatch } = useContext(NotificationContext);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [events, setEvents] = useState<Events[]>([EmptyEvent]);
    const [absence, setAbsence] = useState<Absent>(EmptyAbsent);

    const GetListAbsence = async () => {
        const { status, message, ...response } = await GET(API.USERS_ABSENT);
        if (!status) return dispatch({ message, status });
        setEvents(FormatEvents(response));
    };

    useEffect(() => {
        if (!isOpen) GetListAbsence();
    }, [isOpen]);

    useEffect(() => {
        if (absence.id !== 0) setIsOpen(true);
    }, [absence]);

    return (
        <section className="container mx-auto p-5 w-full">
            <Button variant="default" onClick={() => setIsOpen(true)}>
                Créer une absence
            </Button>
            <Calendar events={events} setState={setAbsence} />
            <AbsentModal open={isOpen} setOpen={setIsOpen} absence={absence} setAbsence={setAbsence} />
        </section>
    );
};

export default UsersCalendar;

import React, { FunctionComponent } from 'react';
import { FaSpinner } from 'react-icons/fa';
import classNames from 'classnames';

interface Loading {
    className?: string;
}

const Loading: FunctionComponent<Loading> = ({ className }) => {
    return (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-sm">
                    <div className="flex flex-col justify-center items-center">
                        <FaSpinner className={classNames('animate-spin w-20 h-20 text-black', className)} />
                        <p>Chargement en cours ...</p>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-30 bg-gray-100" />
        </>
    );
};

export default Loading;

import React, { Fragment } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';

import Label from 'components/atoms/Text';

const LocationCell = (props: {
    value: string;
    row: {
        original: {
            id: string;
            longitude: string;
            latitude: string;
            createAt: string;
            color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
        };
    };
}) => {
    const verif: (string | null | undefined)[] = [null, undefined, '0.0'];
    return (
        <Fragment>
            <span className="flex justify-between">
                <span>
                    <Label text={props.value} color={props.row.original.color} />
                    <span className="text-sm pl-2">{props.row.original.createAt}</span>
                </span>
                {verif.includes(props.row.original.longitude) ? (
                    <FaMapMarkedAlt className="text-lg text-gray-500" />
                ) : (
                    <>
                        <a
                            className="text-sm text-purple-900 hover:underline"
                            target="_blank"
                            href={`https://www.google.com/maps/search/?api=1&query=${props.row.original.longitude},${props.row.original.latitude}`}
                            rel="noreferrer"
                        >
                            <FaMapMarkedAlt className="text-lg" />
                        </a>
                    </>
                )}
            </span>
        </Fragment>
    );
};
export default LocationCell;

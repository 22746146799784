import React, { FunctionComponent } from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { BsCalendar } from 'react-icons/bs';
import fr from 'date-fns/locale/fr'; // the locale you want

interface DatePickerPorps extends ReactDatePickerProps {
    label?: string;
    selected: Date;
    onChange: (date: Date) => void;
}

registerLocale('fr', fr); // register it with the name you want

const DateInput: FunctionComponent<DatePickerPorps> = ({ label, ...props }) => {
    return (
        <div className="flex flex-col justify-center items-start px-2 mb-2 w-full">
            <label className="pb-1 flex flex-col text-sm text-gray-400 max-h-full">{label}</label>
            <div className="flex justify-between w-full">
                <DatePicker
                    className="w-full rounded-l-sm focus:outline-none focus:ring-1 border border-gray-300 focus:ring-purple-700 focus:border-purple-700 h-9 p-2"
                    locale={'fr'}
                    dateFormat="dd/MM/yyyy"
                    {...props}
                />
                <label className="flex rounded-r-sm justify-center items-center px-2 border bg-gray-200 border-gray-300 h-9">
                    <BsCalendar />
                </label>
            </div>
        </div>
    );
};

export default DateInput;

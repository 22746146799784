import classNames from 'classnames';
import { IconType } from 'react-icons';
import React, { useState } from 'react';
import { FaBuilding, FaFile, FaBoxes, FaShippingFast, FaUsers, FaBars, FaMapMarked, FaMapSigns } from 'react-icons/fa';

import Link from 'components/atoms/Link';
import Dropdown from 'components/organisms/Dropdown';
import Navigation from 'components/molecules/Navigation';

import Logo from 'assets/logo.png';
import Routes from 'constants/Routes';
import { useAuthenticateContext } from 'contexts/Authentication';
import { DELETE } from 'utils/Fetch';
import API from 'constants/API';
import { useNavigate } from 'react-router-dom';
import { UserBuilder } from 'models/UsersBuilder/UserBuilder';
import { QueryStringFor } from 'utils/CreateQueries/query';
import Cookies from 'js-cookie';

interface NavigationLinkProps {
    id: string;
    title: string;
    route: Routes | string;
    icon: IconType;
    userRole: string[] | [];
    subRoute?: {
        label: string;
        route: string;
        userRole: string[];
    }[];
}
const Header = () => {
    const [downMenu, setDownMenu] = useState(true);
    const { authentication, setAuthentication } = useAuthenticateContext();
    const navigate = useNavigate();
    const cookies = Cookies.get('session');

    const LogOut = async () => {
        await DELETE(API.LOGOUT);
        Cookies.remove('session');
        setAuthentication(UserBuilder());
        navigate(Routes.LOGIN);
    };

    return (
        <header className="flex flex-col xl:flex-row py-4 bg-purple-700 border-b-2 border-purple-900 shadow-md z-50 h-auto">
            <div className="flex justify-between">
                <Link to={!cookies ? Routes.LOGIN : Routes.HOME} onClick={() => setDownMenu(true)}>
                    <img
                        className="w-48 h-14 my-1"
                        src={Logo}
                        style={{
                            minWidth: '12rem',
                        }}
                    />
                </Link>
                <button
                    type="button"
                    style={{ outline: 'none' }}
                    className="xl:hidden text-gray-200 text-3xl mr-10"
                    onClick={() => setDownMenu(!downMenu)}
                >
                    <FaBars />
                </button>
            </div>
            {cookies && (
                <div
                    className={classNames(
                        'xl:flex flex-col items-start xl:flex-row xl:items-center xl:justify-between xl:w-full',
                        {
                            hidden: downMenu,
                        },
                    )}
                >
                    <Navigation navigationLink={navigationLink} close={() => setDownMenu(!downMenu)} />
                    <Dropdown label={authentication.lastName} options={dropDowninfo} LogOut={LogOut} />
                </div>
            )}
        </header>
    );
};

const navigationLink: NavigationLinkProps[] = [
    {
        id: 'company',
        title: 'Tiers',
        route: Routes.COMPANY,
        icon: FaBuilding,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        subRoute: [
            { label: 'Liste des Tiers', route: Routes.COMPANY, userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
            {
                label: 'Liste des Destinataire',
                route: Routes.COMPANY_DESTINATAIRE,
                userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
            { label: 'Liste des fermetures', route: Routes.COMPANY_CLOSURE, userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
            {
                label: 'Statut des clés',
                route: Routes.COMPANY_KEY_HISTORY,
                userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
            },
        ],
    },
    {
        id: 'document',
        title: 'Documents',
        route: Routes.DOCUMENTS + QueryStringFor('documents'),
        icon: FaFile,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        id: 'package',
        title: 'Colis',
        route: Routes.PACKAGES + QueryStringFor('packageList'),
        icon: FaBoxes,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        id: 'tour',
        title: 'Tournées',
        route: Routes.TOUR,
        icon: FaMapSigns,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        id: 'delivery',
        title: 'Feuilles de route',
        route: Routes.DELIVERY + QueryStringFor('delivery'),
        icon: FaShippingFast,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
    },
    {
        id: 'users',
        title: 'Utilisateurs',
        route: Routes.USERS,
        icon: FaUsers,
        userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'],
        subRoute: [
            { label: 'Liste', route: Routes.USERS, userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
            { label: 'Horaires', route: Routes.USER_SCHEDULE, userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
            { label: 'Absences', route: Routes.USER_CALENDAR, userRole: ['ROLE_ADMIN', 'ROLE_MANAGER'] },
        ],
    },
    {
        id: 'map',
        title: 'Carte',
        route: Routes.MAP + QueryStringFor('map'),
        icon: FaMapMarked,
        userRole: [],
    },
];

interface DropDownProps {
    value: Routes | string;
    label: string;
    userRole: string[] | [];
}

const dropDowninfo: DropDownProps[] = [
    { value: Routes.MY_ACCOUNT, label: 'Mon compte', userRole: [] },
    {
        value: Routes.FILES_HISTORY + QueryStringFor('fileHistory'),
        label: 'Historique des fichiers',
        userRole: ['ROLE_ADMIN'],
    },
];

export default Header;

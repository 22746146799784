/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DeliveriesListProps {
    tourLabel: string;
    statusLabel: string;
    delivererName: string;
    deliveryId: string;
    createAt: string;
    idTournee: string;
    color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
    [key: string]: string;
}

export const DeliveriesListBuilder = (values: Partial<DeliveriesListProps> = {}): DeliveriesListProps[] => [
    {
        tourLabel: '',
        statusLabel: '',
        delivererName: '',
        deliveryId: '',
        createAt: '',
        idTournee: '',
        color: 'none',
        ...values,
    },
];

export const FormatDeliveriesList = ({ data }: any): DeliveriesListProps[] => {
    const array = data.map(
        (data: any): DeliveriesListProps => {
            return {
                tourLabel: data.LibelleTournee,
                statusLabel: data.Libelle_fk_etat,
                delivererName: data.concatNomPrenom,
                deliveryId: data.id_livraison,
                createAt: data.lastHistoryEtat,
                color: data.couleur,
                idTournee: data.idTournee,
            };
        },
    );

    return array;
};

export default DeliveriesListProps;

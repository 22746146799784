import React, { FunctionComponent } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';
import classnames from 'classnames';

interface InputPorps {
    name: string;
    register: React.LegacyRef<HTMLInputElement>;
    label?: string;
    className?: string;
    placeholder?: string;
    messageError?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors?: DeepMap<any, FieldError>;
    type?: 'password' | 'email' | 'tel' | 'time' | 'text';
    variant?: 'primary' | 'secondary' | 'simple';
    inLine?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    autoFocus?: boolean;
}

const Input: FunctionComponent<InputPorps> = ({
    label = null,
    name,
    register,
    errors,
    messageError,
    className,
    inLine = false,
    variant = 'simple',
    hidden,
    disabled,
    ...props
}) => (
    <div
        className={classnames('flex p-1', {
            'flex-col': inLine === false,
            'flex-row items-center justify-between': inLine === true,
        })}
    >
        {label && !hidden && (
            <label htmlFor={name} className="flex flex-col text-sm text-gray-400 max-h-full">
                {label}
            </label>
        )}
        <input
            id={name}
            className={classnames(
                {
                    'border-b-2 m-2 bg-transparent focus:border-purple-800 outline-none':
                        variant === 'primary' && !disabled,
                },
                {
                    'border border-gray-400 rounded-md mb-2 outline-none': variant === 'secondary' && !disabled,
                },
                {
                    'border-b bg-gray-100 p-1 outline-none border-purple-800 ': variant === 'simple' && !disabled,
                },
                {
                    'border-b bg-gray-400 p-1 outline-none border-purple-800': disabled,
                },
                className,
            )}
            ref={register}
            autoComplete={'false'}
            name={name}
            hidden={hidden}
            disabled={disabled}
            {...props}
        />
        <span className="ml-2 text-red-600 font-bold">{errors && errors[name.split('.')[0]] && messageError}</span>
    </div>
);
export default Input;

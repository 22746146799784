import Icon from 'components/atoms/Icon';
import React, { FunctionComponent } from 'react';
import { IconType } from 'react-icons';
import classNames from 'classnames';

interface CardProps {
    icon?: IconType;
    info?: string;
    className?: string;
}

const Card: FunctionComponent<CardProps> = ({ icon, info, className, children }) => {
    return (
        <div className={classNames('border border-purple-400 p-5 flex flex-col', className)}>
            <p className="flex flex-col">
                <span className="flex items-center text-gray-600">
                    {icon && <Icon Icon={icon} className="mr-2" />}
                    {info}
                </span>
            </p>
            {children}
        </div>
    );
};

export default Card;

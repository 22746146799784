import React, { FunctionComponent } from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import { Events } from 'models/UsersBuilder/UsersAbsents';

interface CalendarProps {
    events: Events[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setState?: React.Dispatch<React.SetStateAction<any>>;
}

const Calendar: FunctionComponent<CalendarProps> = ({ events, setState = () => {} }) => {
    return (
        <FullCalendar
            buttonText={{ today: "Aujourd'hui" }}
            viewClassNames="bg-white"
            contentHeight={650}
            weekNumbers
            weekNumberFormat={{ week: 'numeric' }}
            allDayClassNames={'h-10'}
            dayCellClassNames="w-5"
            locale={'fr'}
            nowIndicator
            timeZone="local"
            firstDay={1}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={events}
            eventClick={(info) => setState(info.event.extendedProps)}
        />
    );
};

export default Calendar;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { FaCalendarAlt, FaTruck, FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa';
import { Circle, MapContainer, Marker, Polyline, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { FaCheckCircle, FaQuestionCircle } from 'react-icons/fa';
import Card from 'components/molecules/Card';
import DateInput from 'components/molecules/Inputs/DatePicker';
import API from 'constants/API';
import { GET } from 'utils/Fetch';
import FormatDate, { compareDate, localDate } from 'utils/FormatDate';
import { NotificationContext } from 'contexts/Notifications';
import DeliveriesMapsProps, { colorScale, DeliveryHistory, DeliveryList, FormatListDelivere } from 'models/MapBuilder';
import Icon from 'components/atoms/Icon';
import classNames from 'classnames';
import Loading from 'components/organisms/Modal/Loading';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryToState, QueryToString } from 'utils/CreateQueries/query';

const Map = () => {
    const [date, setDate] = useState<Date>(new Date());
    const [delivery, setDelivery] = useState<DeliveriesMapsProps[]>(DeliveryHistory());
    const [loadingPage, setLoadingPage] = useState<boolean>(true);
    const [, setSearchParams] = useSearchParams();
    const { dispatch } = useContext(NotificationContext);
    const [showPharmacist, setShowParma] = useState<boolean>(true);
    const [showUserPos, setShowUserPos] = useState<boolean>(false);
    const [extendMap, setExtendMap] = useState<boolean>(false);
    const search = useLocation().search;

    const mapRef = useRef();

    const UpdateFromParams = () => {
        const urlParams = QueryToState(search);
        setDate(new Date(urlParams.date));
        setLoadingPage(true);
        GetDeliveryHistory(QueryToString(urlParams));
    };

    useEffect(() => {
        UpdateFromParams();
    }, []);

    const FilterToParams = (date: Date) => {
        setDate(date);
        setSearchParams({ date: FormatDate(date) });
        GetDeliveryHistory(QueryToString({ date: FormatDate(date) }));
    };

    const GetDeliveryHistory = async (params: string) => {
        setLoadingPage(true);
        const { status, message, ...response } = await GET(`${API.MAP_HISTORY + params}`);
        if (!status) return dispatch({ status, message });
        const deliveryHistory = FormatListDelivere(response);

        setDelivery(deliveryHistory);
        setLoadingPage(false);
    };

    const ShowingStatus = async (index: number, deliveryId: number, userId: number) => {
        setLoadingPage(true);
        const newdelivery = [...delivery];

        newdelivery[index].showList = !newdelivery[index].showList;

        if (newdelivery[index].deliveryList.length <= 0) {
            const { status, data } = await GET(API.TIER_DELIVERY + `?livraisonid=${deliveryId}`);
            if (status) newdelivery[index].deliveryList = data;
        }

        if (newdelivery[index].userGpsHistory.length <= 0 || compareDate(date, localDate(), 'day') >= 0) {
            const { status, data } = await GET(API.USER_GPS_HISTORY + `?userid=${userId}&livraisonid=${deliveryId}`);
            if (status) newdelivery[index].userGpsHistory = data;
        }

        setDelivery(newdelivery);
        setLoadingPage(false);
    };

    return (
        <>
            {loadingPage && <Loading />}
            <section className="container mx-auto my-2 px-2 grid grid-cols-1 grid-rows-6 md:grid-rows-4 lg:grid-cols-6 gap-1 lg:gap-5">
                <Card
                    className="col-start-1 row-start-1 row-span-1 lg:col-start-5 lg:col-span-2 lg:row-span-1"
                    icon={FaCalendarAlt}
                    info={'Livraison'}
                >
                    <DateInput selected={date} onChange={FilterToParams} />
                    <label htmlFor={'checkPharma'} className="flex py-2">
                        <input
                            id={'checkPharma'}
                            onChange={() => setShowParma(!showPharmacist)}
                            checked={showPharmacist}
                            type="checkbox"
                            className="text-base mr-2 text-black"
                        />
                        Afficher les pharmacies
                    </label>
                    <label htmlFor="userPosistion" className="flex py-2">
                        <input
                            id="userPosistion"
                            onChange={() => setShowUserPos(!showUserPos)}
                            checked={showUserPos}
                            type="checkbox"
                            className="text-base mr-2 text-black"
                        />
                        Afficher les positions du chauffeur
                    </label>
                </Card>
                <Card
                    className="row-start-2 row-span-2 lg:col-start-5 lg:row-start-2 lg:row-span-3 lg:col-span-2"
                    icon={FaTruck}
                    info={'Livreur'}
                >
                    <div className="flex flex-wrap">
                        {delivery.map(
                            ({ user: userName, userId, deliveryId, showList, tour }: DeliveriesMapsProps, index) => {
                                return (
                                    <label key={index} htmlFor={`${deliveryId}`} className="flex py-2 w-1/2">
                                        <input
                                            id={`${deliveryId}`}
                                            onChange={() => ShowingStatus(index, deliveryId, userId)}
                                            checked={showList}
                                            type="checkbox"
                                            className="text-base mr-2 text-black"
                                        />
                                        <p className="flex flex-col">
                                            {userName}
                                            <span className="text-xs text-gray-400">{tour}</span>
                                        </p>
                                    </label>
                                );
                            },
                        )}
                    </div>
                </Card>
                <div
                    className={classNames(
                        'transition h-full w-full border border-indigo-800 row-start-4 row-span-3 lg:row-start-1 lg:col-span-4 lg:row-span-4 lg:row-end-5 overflow-hidden bg-red-500',
                        {
                            'h-screen w-screen z-50 bottom-0 right-0 absolute': extendMap,
                            'z-0': !extendMap,
                        },
                    )}
                >
                    <MapContainer
                        ref={mapRef}
                        key={extendMap ? '1' : '0'}
                        className="relative"
                        center={[48.8566, 2.3522]}
                        zoom={10}
                        scrollWheelZoom={true}
                    >
                        <button
                            onClick={() => {
                                setExtendMap(!extendMap);
                            }}
                            className="absolute !z-[400] top-3 right-3 shadow-2xl border-2 border-gray-400 rounded-md bg-white"
                        >
                            <Icon
                                Icon={extendMap ? FaCompressArrowsAlt : FaExpandArrowsAlt}
                                className={classNames('w-10 m-1 text-2xl')}
                            />
                        </button>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {delivery.map((livraison, index) =>
                            livraison.deliveryList.map(
                                (pharmacie: DeliveryList, jindex) =>
                                    livraison.showList &&
                                    showPharmacist && (
                                        <>
                                            {' '}
                                            {pharmacie.latitude !== null && pharmacie.longitude !== null && (
                                                <Marker position={[pharmacie.latitude, pharmacie.longitude]}>
                                                    <Popup
                                                        className="group z-10 hover:z-40 bg-white shadow-lg w-60"
                                                        autoClose={true}
                                                        closeButton={false}
                                                        key={jindex + 1001}
                                                        closeOnClick={true}
                                                    >
                                                        <div className="w-60">{pharmacie.raisonSocial}</div>
                                                    </Popup>
                                                </Marker>
                                            )}
                                            {/* <Popup
                                                className="group z-10 hover:z-40 bg-white shadow-lg"
                                                autoClose={false}
                                                closeButton={false}
                                                key={jindex + 1001}
                                                closeOnClick={false}
                                                position={{ lat: pharmacie.scanLongitude, lng: pharmacie.scanLatitude }}
                                            >
                                                <Icon
                                                    Icon={pharmacie.livrer === 1 ? FaCheckCircle : FaQuestionCircle}
                                                    className={classNames(
                                                        'absolute rounded-full -left-2 -top-1 z-50 bg-white text-green-700 text-base',
                                                        {
                                                            'text-green-700': pharmacie.livrer === 1,
                                                            'text-orange-700': pharmacie.livrer === 0,
                                                        },
                                                    )}
                                                />
                                                <div
                                                    style={{ backgroundColor: colorScale[index] }}
                                                    className="relative h-full w-full flex justify-center text-white font-bold"
                                                >
                                                    {jindex + 1001}
                                                </div>
                                                <div
                                                    className={classNames(
                                                        'absolute hidden group-hover:min-h-full group-hover:flex flex-col bg-white top-5 group-hover:w-40 p-1 z-40',
                                                    )}
                                                >
                                                    <span className="font-bold break-all">
                                                        {pharmacie.raisonSocial.toLowerCase()}
                                                    </span>
                                                    <span>
                                                        scanné à:
                                                        <span className="font-bold ml-1">
                                                            {pharmacie.scanDate.split(' ')[1]}
                                                        </span>
                                                    </span>
                                                </div>
                                            </Popup> */}
                                            <Popup
                                                className="group z-10 hover:z-40 bg-white shadow-lg"
                                                autoClose={false}
                                                closeButton={false}
                                                key={jindex}
                                                closeOnClick={false}
                                                position={{ lat: pharmacie.scanLongitude, lng: pharmacie.scanLatitude }}
                                            >
                                                <>
                                                    <Icon
                                                        Icon={pharmacie.livrer === 1 ? FaCheckCircle : FaQuestionCircle}
                                                        className={classNames(
                                                            'absolute rounded-full -left-2 -top-1 z-50 bg-white text-green-700 text-base',
                                                            {
                                                                'text-green-700': pharmacie.livrer === 1,
                                                                'text-orange-700': pharmacie.livrer === 0,
                                                            },
                                                        )}
                                                    />
                                                    <div
                                                        style={{ backgroundColor: colorScale[index] }}
                                                        className="relative h-full w-full flex justify-center text-white font-bold"
                                                    >
                                                        {jindex + 1}
                                                    </div>
                                                    <div
                                                        className={classNames(
                                                            'absolute hidden group-hover:min-h-full group-hover:flex flex-col bg-white top-5 group-hover:w-60 p-1 z-40',
                                                        )}
                                                    >
                                                        <span className="font-bold break-all">
                                                            {pharmacie.raisonSocial.toLowerCase()}
                                                        </span>
                                                        <span className="text-xs">
                                                            {pharmacie.adresse.toLowerCase()}
                                                        </span>
                                                        <span className="mb-2 text-xs">
                                                            {pharmacie.codePostale} {pharmacie.ville.toLowerCase()}
                                                        </span>
                                                        <span className="mb-2 text-xs break-all">
                                                            {pharmacie.openingHours}
                                                        </span>
                                                        <span>
                                                            {/* En possession de la  */}
                                                            clé:
                                                            <span className="font-bold ml-1">
                                                                {pharmacie.hasKeys ? 'Oui' : 'Non'}
                                                            </span>
                                                        </span>
                                                        <span>
                                                            Livré à:
                                                            <span className="font-bold ml-1">
                                                                {pharmacie.date.split(' ')[1]}
                                                            </span>
                                                        </span>
                                                        <span>
                                                            Scanné à:
                                                            <span className="font-bold ml-1">
                                                                {pharmacie.scanDate.split(' ')[1]}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </>
                                            </Popup>
                                        </>
                                    ),
                            ),
                        )}
                        {delivery.map((users, index) =>
                            users.userGpsHistory.map(
                                (user, jindex) =>
                                    users.showList &&
                                    showUserPos &&
                                    (jindex !== 0 && jindex !== users.userGpsHistory.length - 1 ? (
                                        <Circle
                                            key={jindex}
                                            center={{ lat: user.lat, lng: user.lng }}
                                            radius={10}
                                            color={colorScale[index]}
                                        >
                                            <Tooltip>{user.timePosition}</Tooltip>
                                        </Circle>
                                    ) : (
                                        <Marker position={user}>
                                            <Tooltip>{jindex === 0 ? 'Départ' : 'Arriver'}</Tooltip>
                                        </Marker>
                                    )),
                            ),
                        )}
                        {delivery.map(
                            (users, index) =>
                                users.showList &&
                                showUserPos && (
                                    <Polyline
                                        key={index}
                                        pathOptions={{ color: colorScale[index] }}
                                        positions={users.userGpsHistory}
                                    />
                                ),
                        )}
                    </MapContainer>
                </div>
            </section>
        </>
    );
};

export default Map;

import React, { createContext, SetStateAction, Dispatch, useState, FunctionComponent, useContext } from 'react';
import UserProps, { UserBuilder } from 'models/UsersBuilder/UserBuilder';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from 'constants/Routes';
import Loading from 'components/organisms/Modal/Loading';

interface AuthenticationProps {
    authentication: UserProps;
    setAuthentication: Dispatch<SetStateAction<UserProps>>;
    SaveUser: (arg0: UserProps) => void;
}

const AuthenticationContext = createContext<AuthenticationProps>({
    authentication: UserBuilder(),
    setAuthentication: () => {},
    SaveUser: () => {},
});

const AuthenticationProvider: FunctionComponent = ({ children }) => {
    const [authentication, setAuthentication] = useState<UserProps>(UserBuilder());
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const isAuth = () => {
        const cookies = Cookies.get('session');
        if (!cookies) {
            navigate(Routes.LOGIN);
        } else {
            const userInfo = localStorage.getItem('session');
            if (userInfo) setAuthentication(JSON.parse(userInfo));
        }
        return setLoading(false);
    };

    useEffect(() => {
        isAuth();
    }, [navigate]);

    const parseJwt = (token: string) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    };

    const SetCookieTime = (User: UserProps): Date => {
        const dateFrom0 = new Date(0);
        const { token, ...user } = User;
        localStorage.setItem('session', JSON.stringify(user));
        dateFrom0.setUTCSeconds(parseJwt(token).exp).toLocaleString();
        Cookies.set('session', token, { expires: dateFrom0 });
        return dateFrom0;
    };

    const SaveUser = (User: UserProps) => {
        setAuthentication(User);
        SetCookieTime(User);
        navigate(Routes.HOME);
    };

    return (
        <AuthenticationContext.Provider value={{ authentication, setAuthentication, SaveUser }}>
            {loading && <Loading />}
            {children}
        </AuthenticationContext.Provider>
    );
};

const useAuthenticateContext = () => {
    const store = useContext(AuthenticationContext);
    if (!store) throw new Error('Cannot use `useStore` outside of a StoreProvider');
    return store;
};

export { useAuthenticateContext, AuthenticationProvider };

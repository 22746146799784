import React, { FunctionComponent, useContext, useEffect, useState } from 'react';

import Button from 'components/atoms/Buttons';

import Select from 'components/atoms/Form/Select';
import TextArea from 'components/atoms/Form/textArea';
import DateInput from 'components/molecules/Inputs/DatePicker';
import { Controller, useForm } from 'react-hook-form';
import { GET, PATCH, POST } from 'utils/Fetch';
import API from 'constants/API';
import { FormatSelectUser } from 'models/UsersBuilder/UserBuilder';
import { NotificationContext } from 'contexts/Notifications';
import { FormatOptMotive } from 'models/Options';
import { Absent, AbsentForm, EmptyAbsent, ReFormatAbsent } from 'models/UsersBuilder/UsersAbsents';
import DropdownCombobox from 'components/atoms/Form/downShift';
import CheckBox from 'components/molecules/CheckBox';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    absence?: Absent;
    setAbsence?: React.Dispatch<React.SetStateAction<Absent>>;
}

const AbsentModal: FunctionComponent<ModalProps> = ({ open = false, setOpen, absence, setAbsence = () => {} }) => {
    const { dispatch } = useContext(NotificationContext);

    const [deliverList, setDeliverList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const [motiveList, setMotiveList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const [loading, setLoading] = useState<boolean>(true);

    const { register, errors, handleSubmit, control, reset, setValue, watch } = useForm<Absent>({
        defaultValues: absence,
        mode: 'onSubmit',
    });

    const GetDeliverList = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ message, status });
        setDeliverList(FormatSelectUser(response));
        SetValue();
        setLoading(false);
    };

    const GetMotive = async () => {
        const { status, message, ...response } = await GET(API.ABSENT_MOTIVE);
        if (!status) return dispatch({ message, status });
        setMotiveList(FormatOptMotive(response));
        SetValue();
    };

    useEffect(() => {
        if (!open) return;
        GetDeliverList();
        GetMotive();
        document.addEventListener('keydown', function (event) {
            if (event.key === 'Escape') {
                setOpen(false);
                setAbsence(EmptyAbsent);
            }
        });
    }, [open]);

    const SetValue = () => {
        if (absence) {
            setValue('userId', absence.userId);
            setValue('motif', absence.motif);
            reset({ ...absence });
        }
    };

    const onSubmit = async (data: AbsentForm) => {
        const newAbsent = ReFormatAbsent(data);
        if (absence && absence.id !== 0) {
            newAbsent.id = absence.id;
            const { status, message } = await PATCH(API.ABSENT_SAVE, newAbsent);
            if (!status) return dispatch({ message, status });
            dispatch({ message, status });
            setOpen(false);
            return;
        }
        const { status, message } = await POST(API.ABSENT_SAVE, newAbsent);
        if (!status) return dispatch({ message, status });
        dispatch({ message, status });
        setAbsence(EmptyAbsent);
        setOpen(false);
    };

    return (
        <>
            {open && !loading && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-sm">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                                    <h3 className="text-xl font-semibold">Création d&apos;abscence</h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form id="AbsentForm" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                        <Controller
                                            control={control}
                                            error={errors}
                                            name="userId"
                                            rules={{ required: true }}
                                            render={({ onChange }) => (
                                                <>
                                                    <DropdownCombobox
                                                        label="Choisir l'utilisateur"
                                                        items={deliverList}
                                                        onChange={onChange}
                                                        defaultValue={{
                                                            label: absence ? absence?.user : '',
                                                            value: absence ? absence?.userId : '',
                                                        }}
                                                    />
                                                    {errors['userId'] && (
                                                        <span className="ml-2 text-red-600">
                                                            {errors['userId'] && 'Veuillez sélectionner un tiers'}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        />
                                        <Select
                                            label="Motif"
                                            name="motif"
                                            errors={errors}
                                            messageError="Veuillez sélectionner un motif"
                                            options={motiveList}
                                            register={register({ required: true })}
                                        />
                                        <Controller
                                            control={control}
                                            error={errors}
                                            name="startDate"
                                            defaultValue={new Date()}
                                            rules={{ required: true }}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <DateInput
                                                        label="Début"
                                                        selected={value}
                                                        onChange={onChange}
                                                        startDate={watch('startDate')}
                                                        endDate={watch('endDate')}
                                                    />
                                                    {errors['startDate'] && (
                                                        <span className="ml-2 text-red-600">
                                                            {errors['startDate'] && 'Séléctionner une date'}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        />
                                        <Controller
                                            control={control}
                                            error={errors}
                                            name="endDate"
                                            defaultValue={new Date()}
                                            rules={{ required: true }}
                                            render={({ onChange, value }) => (
                                                <>
                                                    <DateInput
                                                        label="Fin"
                                                        selected={value}
                                                        onChange={onChange}
                                                        selectsEnd
                                                        startDate={watch('startDate')}
                                                        endDate={watch('endDate')}
                                                        minDate={watch('startDate')}
                                                    />
                                                    {errors['endDate'] && (
                                                        <span className="ml-2 text-red-600 font-bold">
                                                            {errors['endDate'] && 'Séléctionner une date'}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        />

                                        <TextArea label="Autre" name={'comment'} register={register} />
                                        <CheckBox register={register} name="valid" label={'Valider'} id={'valid'} />
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                    <Button
                                        onClick={() => [setOpen(false), setAbsence(EmptyAbsent)]}
                                        variant="secondary"
                                    >
                                        Annuler
                                    </Button>
                                    <Button form="AbsentForm" type="submit" variant="primary">
                                        Valider
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            )}
        </>
    );
};

export default AbsentModal;

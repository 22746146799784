import Table from 'components/organisms/Table/ReactTable/SyncTable';
import API from 'constants/API';
import Routes from 'constants/Routes';
import { NotificationContext } from 'contexts/Notifications';
import { UserScheduleBuilder, Schedule, FormatUserSchedule } from 'models/UsersBuilder/UserSchedule';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DELETE, GET } from 'utils/Fetch';
import { FaTrash, FaRegEdit } from 'react-icons/fa';
import SchedulForm from './scheduleForm';
import Button from 'components/atoms/Buttons';
import ConfirmDelete from 'pages/CompaniesList/ConfirmDelete';

const UserSchedule = () => {
    const { dispatch } = useContext(NotificationContext);

    const [schedules, setSchedules] = useState<Schedule[]>(UserScheduleBuilder());
    const [openForm, setOpenForm] = useState<boolean>(false);
    const [userSchedule, setUserSchedule] = useState<Schedule>(UserScheduleBuilder()[0]);
    const [scheduleId, setScheduleId] = useState<number>(0);
    const [comfirmDelete, setConfirmDelete] = useState<boolean>(false);

    const GetUsersSchedule = async () => {
        const { status, message, ...response } = await GET(API.USER_SCHEDULE_LIST);
        if (!status) return dispatch({ status, message });
        setSchedules(FormatUserSchedule(response));
    };

    useEffect(() => {
        if (!openForm) GetUsersSchedule();
    }, [openForm]);

    const DeleteSchedule = async (id: string) => {
        const { status, message } = await DELETE(`${API.USER_SCHEDULE}/${id}`);
        if (!status) return dispatch({ status, message });
        dispatch({ status, message });
        GetUsersSchedule();
        setConfirmDelete(false);
    };

    const IdCell = (props: { column: { Header: string }; value: string; row: { original: Schedule } }) => (
        <Fragment>
            <span className="flex justify-between">
                <Link to={`${Routes.USER}/${props.row.original.userId}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.value}</p>
                </Link>
                <button
                    type="button"
                    className="outlined-none text-red-600  px-1"
                    onClick={() => {
                        setConfirmDelete(true);
                        setScheduleId(props.row.original.id);
                    }}
                >
                    <FaTrash />
                </button>
                <button
                    type="button"
                    className="outlined-none text-purple-600 px-1 text-lg"
                    onClick={() => {
                        setOpenForm(true);
                        setUserSchedule(props.row.original);
                    }}
                >
                    <FaRegEdit />
                </button>
            </span>
        </Fragment>
    );

    const columns = [
        {
            Header: 'Nom Prénom',
            accessor: 'userName',
            className: 'text-left border border-gray-200  px-2 w-1/3',
            Cell: IdCell,
        },
        {
            Header: 'Lundi',
            accessor: 'days.monday',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Mardi',
            accessor: 'days.tuesday',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Mercredi',
            accessor: 'days.wednesday',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Jeudi',
            accessor: 'days.thursday',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Vendredi',
            accessor: 'days.friday',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Samedi',
            accessor: 'days.saturday',
            className: 'text-left border border-gray-200  px-2',
        },
    ];

    return (
        <section className="container mx-auto my-5">
            <Button onClick={() => setOpenForm(true)}>Créer horaire</Button>
            <Table columns={columns} data={schedules} sortBy="userName" />
            <SchedulForm open={openForm} setOpen={setOpenForm} schedule={userSchedule} setSchedule={setUserSchedule} />
            <ConfirmDelete
                open={comfirmDelete}
                setOpen={setConfirmDelete}
                id={`${scheduleId}`}
                Delete={DeleteSchedule}
                label="schedule"
            />
        </section>
    );
};

export default UserSchedule;

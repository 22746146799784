import React, { useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import Button from 'components/atoms/Buttons';
import Input from 'components/atoms/Form/input';
import Notification from 'components/atoms/Notifications';

import API from 'constants/API';
import { POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { useAuthenticateContext } from 'contexts/Authentication';
import { FormatUser } from 'models/UsersBuilder/UserBuilder';

const Login = () => {
    const { SaveUser } = useAuthenticateContext();
    const { state, dispatch } = useContext(NotificationContext);
    const { register, handleSubmit, errors } = useForm();

    const onSubmit = async (body: FieldValues) => {
        const { status, message, ...response } = await POST(API.LOGIN, body);
        if (!status) return dispatch({ status, message });
        SaveUser(FormatUser(response));
    };

    return (
        <section className="flex flex-col items-center justify-center mt-10">
            <div className="flex flex-col items-center">
                <h2 className="uppercase text-lg text-purple-900 font-bold ">Identification obligatoire</h2>
                <form
                    id="LoginForm"
                    autoComplete="off"
                    className="flex flex-col items-center m-5"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Notification message={state.message} status={state.status} />
                    <Input
                        name="login"
                        placeholder="Identifiant"
                        register={register({ required: true })}
                        messageError="L'identifiant est requis"
                        errors={errors}
                        autoFocus={true}
                    />
                    <Input
                        name="password"
                        placeholder="Mot de passe"
                        register={register({ required: true })}
                        messageError="Entrer un mot de passe"
                        errors={errors}
                        type="password"
                    />
                </form>
                <Button form="LoginForm" type="submit" className="w-48 text-lg rounded-lg">
                    Connexion
                </Button>
            </div>
        </section>
    );
};

export default Login;

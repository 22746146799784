import dayjs, { QUnitType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const compareDate = (date1: any, date2: any, diff?: QUnitType) => {
    return dayjs(date1).diff(date2, diff);
};

export const localDate = () => {
    return dayjs().format('YYYY-MM-DD');
};
export const getPreviousDay = (date = new Date()) => {
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - 1);

    return previous;
};

const FormatDate = (watchAllFields: Date) => {
    let customeDate = '';
    const date = new Date(watchAllFields).getDate();
    const month = new Date(watchAllFields).getMonth() + 1;
    const year = new Date(watchAllFields).getFullYear();
    let _month = '';
    let _date = '';

    if (month.toString().length === 1) {
        _month = `0${month}`;
    } else {
        _month = month.toString();
    }

    if (date.toString().length === 1) {
        _date = `0${date}`;
    } else {
        _date = date.toString();
    }

    customeDate = `${year}-${_month}-${_date}`;

    return customeDate;
};

export default FormatDate;

import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';

import Rows from 'components/molecules/Row';
import Button from 'components/atoms/Buttons';
import Input from 'components/atoms/Form/input';
import Select from 'components/atoms/Form/Select';
import CheckBox from 'components/molecules/CheckBox';
import TextArea from 'components/atoms/Form/textArea';
import DownShift from 'components/atoms/Form/downShift';

import API from 'constants/API';
import { days } from 'constants/Options';
import { ExportCSV } from 'utils/ExportCSV';
import { GET, PATCH, POST } from 'utils/Fetch';
import { FormatSelectUser } from 'models/UsersBuilder/UserBuilder';
import { NotificationContext } from 'contexts/Notifications';
import { FormatCompaniesList, CompaniesList } from 'models/CompanyBuilder/CompaniesList';
import { FormatTour, TourOrder, TourProps, UnFormatTour } from 'models/TourBuilder';

const Tour = () => {
    const { id = '' } = useParams<{ id: string }>();
    const [selectedTiers, setSelectedTiers] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [listOfTiers, setListOfTiers] = useState<TourOrder[]>([]);
    const [deliverList, setDeliverList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const [centreLogistique, setCentreLogistique] = useState<{ label: string; value: string }[]>([
        { label: '', value: '' },
    ]);
    const [allTiersList, setAllTiersList] = useState<CompaniesList[]>([]);

    const { register, handleSubmit, reset } = useForm();
    const { dispatch } = useContext(NotificationContext);
    const navigate = useNavigate();
    const [codeDisabled, setCodeDisabled] = useState(false);

    const GetTourInfo = async () => {
        if (deliverList.length === 1) {
            const res = await GetDeliverList();
            if (!res) return;
        }
        const { status, message, ...response } = await GET(`${API.TOUR}/${id}`);
        if (!status) {
            dispatch({ status, message });
            return navigate(-1);
        }
        const tour = FormatTour(response);
        setListOfTiers(tour.tourOrder);
        reset({ ...tour });
        setCodeDisabled(tour.codeTournee != null && tour.codeTournee != '');
        setLoading(false);
    };

    const GetDeliverList = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ message, status });
        setDeliverList(FormatSelectUser(response));
        return status;
    };

    const GetTiersList = async () => {
        const { status, message, ...response } = await GET(API.TIERS_LIST);
        if (!status) return dispatch({ message, status });
        setAllTiersList(FormatCompaniesList(response));
        setLoading(false);
    };

    const GetCentreLogistique = async () => {
        const { status, message, ...response } = await GET(API.OPTIONS_CENTRE_LOGISTIQUE);
        if (!status) return dispatch({ message, status });
        setCentreLogistique(response.data);
    };
    useEffect(() => {
        if (id !== '0') GetTourInfo();
        if (id === '0') GetDeliverList();
        GetTiersList();
        GetCentreLogistique();
    }, []);

    const AddTiersToList = async () => {
        const tiers = allTiersList.filter((item: CompaniesList) => {
            if (item.id) return +item.id === +selectedTiers;
        });
        const addToRow = {
            address: tiers[0].address,
            name: tiers[0].name,
            tiersId: tiers[0].id === null ? 0 : tiers[0].id,
            order: listOfTiers.length,
        };

        const newList = listOfTiers.slice();

        newList.push(addToRow);
        setListOfTiers(newList);
        return newList;
    };

    const UpdateList = async () => {
        const newList = await AddTiersToList();
        UpdateOrder(newList);
    };
    const UpdateOrder = async (list = listOfTiers) => {
        const orderIdList = list.map((data: TourOrder) => data.tiersId);
        const body = {
            idTournee: +id,
            listTiersOrder: orderIdList,
        };
        const { status, message } = await POST(API.TOUR_UPDATE_ORDER, body);
        dispatch({ status, message });
        if (status) GetTourInfo();
    };

    const StartTour = async (lendemain: number) => {
        const { status, message } = await POST(`${API.TOUR_START}/${id}?lendemain=${lendemain}`);
        dispatch({ status, message });
    };

    const onSubmit = async (data: TourProps) => {
        const body = UnFormatTour(data);
        body.id = id === '0' ? null : +id;
        body.horairesDuLendemain = body.horairesDuLendemain ? 1 : 0;
        setCodeDisabled(data.codeTournee != null && data.codeTournee != '');

        if (id === '0') {
            const { status, message, data } = await POST(API.TOUR, body);
            if (status) navigate(`/tournee/${data[0].id}`, { replace: true });
            return dispatch({ status, message });
        }

        const { status, message } = await PATCH(API.TOUR, body);
        return dispatch({ status, message });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <section className="container mx-auto flex flex-wrap justify-center mt-5">
            <h1 className="w-full text-3xl mb-5 text-center">
                {id === '0' ? "Création d'une tournée" : "Édition d'une tournée"}
            </h1>
            <div className="flex flex-col border-2 border-purple-500 px-2 py-1 w-1/5 h-full">
                <form id="form" onSubmit={handleSubmit(onSubmit)} className="w-auto">
                    <Input label="Libelle" name="label" register={register} />
                    <Input label="Code tournée" name="codeTournee" register={register} disabled={codeDisabled} />
                    <CheckBox
                        id="horairesDuLendemain"
                        label="Tournée sur le lendemain"
                        name="horairesDuLendemain"
                        register={register}
                    />
                    <p className="ml-5">Jours de livraison</p>
                    {days.map((data, index) => {
                        return (
                            <CheckBox
                                key={index}
                                id={data.label}
                                label={data.label}
                                name={`days.${data.label}`}
                                register={register}
                            />
                        );
                    })}
                    <Select placeHolder="livreur" name="deliverId" options={deliverList} register={register} />
                    <Select
                        placeHolder="Centre Logistique"
                        name="centreLogistique"
                        options={centreLogistique}
                        register={register}
                    />
                    <TextArea placeholder="Description" name="description" register={register} />
                </form>
                <Button form="form" type="submit">
                    {id === '0' ? 'Enregistrer' : 'Mettre à jour'}
                </Button>
            </div>
            <div className="w-4/5 px-2">
                <DownShift placeholder="Choisir le tiers" items={allTiersList} onChange={setSelectedTiers} />
                <div className="flex items-center w-full mb-2">
                    <Button
                        className="w-56"
                        disable={id === '0' ? true : false}
                        onClick={() => {
                            if (selectedTiers !== '') UpdateList();
                        }}
                    >
                        Ajouter tiers
                    </Button>
                    <Button
                        className="w-56"
                        variant="primary"
                        disable={id === '0' ? true : false}
                        bold
                        onClick={() => StartTour(0)}
                    >
                        Mise en Livraison
                    </Button>
                    <Button
                        className="w-66"
                        variant="primary"
                        disable={id === '0' ? true : false}
                        bold
                        onClick={() => StartTour(1)}
                    >
                        Mise en Livraison le lendemain
                    </Button>
                    <Button
                        className="w-56"
                        variant="outline"
                        disable={id === '0' ? true : false}
                        bold
                        onClick={() => {
                            if (listOfTiers.length !== 0) ExportCSV(listOfTiers);
                        }}
                    >
                        Exporter tiers
                    </Button>
                </div>
                <Rows lists={listOfTiers} setList={setListOfTiers} setUpdate={UpdateOrder} />
            </div>
        </section>
    );
};

export default Tour;

import { Link } from 'react-router-dom';
import React, { Fragment } from 'react';
import Label from 'components/atoms/Text';
import Routes from 'constants/Routes';
import dayjs from 'dayjs';

const DeliveryCell = (props: { value: string; row: { original: { deliveryId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DELIVERY}/${props.row.original.deliveryId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const StatusCell = (props: {
    value: string;
    row: {
        original: {
            id: string;
            longitude: string;
            latitude: string;
            dateCreation: string;
            color: 'success' | 'secondary' | 'info' | 'warning' | 'none';
        };
    };
}) => (
    <Fragment>
        <span className="flex justify-between">
            <Label text={props.value} color={props.row.original.color} />
        </span>
    </Fragment>
);

export const deliveryColumn = [
    {
        Header: 'Numéro livraison',
        accessor: 'deliveryId',
        Cell: DeliveryCell,
    },
    {
        Header: 'Tournée',
        accessor: 'tourLabel',
        Cell: (props: { row: { original: { idTournee: string; tourLabel: string } } }) => (
            <Fragment>
                <span className="flex justify-between">
                    <Link to={`${Routes.TOUR}/${props.row.original.idTournee}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">{props.row.original.tourLabel}</p>
                    </Link>
                </span>
            </Fragment>
        ),
    },
    {
        Header: 'Livreur',
        accessor: 'delivererName',
    },

    {
        Header: 'Date',
        accessor: 'createAt',
        Cell: (props: { value: string; row: { original: { deliveryId: string } } }) => (
            <span>{dayjs(props.value).format('DD/MM/YYYY HH:mm')}</span>
        ),
    },
    {
        Header: 'Etat',
        accessor: 'statusLabel',
        Cell: StatusCell,
    },
];

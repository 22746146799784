/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatDate from 'utils/FormatDate';
///// FORM /////
/** Type of AbsentForm */
export interface AbsentForm {
    id: null | number;
    endDate: Date;
    startDate: Date;
    motif: string;
    userId: string;
    valid: boolean;
    comment: string;
}
/** Reformat data for POST new absence */
export const ReFormatAbsent = (data: AbsentForm) => ({
    id: data.id ? data.id : null,
    commentaire: data.comment,
    end_date: FormatDate(data.endDate),
    start_date: FormatDate(data.startDate),
    fk_motif_absence: +data.motif,
    fk_utilisateur: +data.userId,
    absence_valide: data.valid ? 1 : 0,
});

///// EVENTS /////
/** Events for calendar */
export interface Events {
    groupId: string;
    start: string;
    end: string;
    color: string;
    title: string;
    extendedProps: Record<string, unknown>;
}
/** Empty event */
export const EmptyEvent: Events = {
    groupId: '',
    start: '',
    end: '',
    color: '',
    title: '',
    extendedProps: {},
};
/** Format Events for calendar */
export const FormatEvents = ({ data }: any) => {
    const DATA = { data };
    return data.map(
        (element: any, index: number): Events => ({
            groupId: element.concatNomPrenom + index,
            start: element.start_date,
            end: element.end_date,
            color: Colors[index],
            title: `${element.concatNomPrenom}: ${element.libelleMotifAbsence}`,
            extendedProps: {
                ...FormatAbsent(DATA)[index],
            },
        }),
    );
};

///// Generic Absent /////
export interface Absent extends AbsentForm {
    user: string;
    label: string;
}

export const EmptyAbsent: Absent = {
    comment: '',
    endDate: new Date(),
    startDate: new Date(),
    motif: '',
    user: '',
    userId: '',
    valid: false,
    id: 0,
    label: '',
};

export const FormatAbsent = ({ data }: any) =>
    data.map(
        (elem: any): Absent => ({
            valid: elem.absence_valide,
            endDate: new Date(elem.end_date),
            startDate: new Date(elem.start_date),
            comment: elem.commentaire,
            user: elem.concatNomPrenom,
            userId: elem.fk_utilisateur,
            id: elem.id,
            label: elem.libelleMotifAbsence,
            motif: elem.fk_motif_absence,
        }),
    );

const Colors = ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600'];

export const AbsentBuilder = () => ({
    comment: '',
    endDate: '',
    startDate: '',
    motif: 0,
    user: '',
    userId: 0,
    valid: false,
    id: 0,
    label: '',
});

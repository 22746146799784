import React, { FunctionComponent } from 'react';

import Button from 'components/atoms/Buttons';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    message: string;
}

const ModalMessage: FunctionComponent<ModalProps> = ({ open = false, setOpen, message }) => {
    return (
        <>
            {open && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="w-auto my-6 mx-auto max-w-lg">
                            <div className="border-0 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none">
                                {message}

                                <Button onClick={() => setOpen(!open)} type="submit" variant="primary">
                                    Ok
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            )}
        </>
    );
};

export default ModalMessage;

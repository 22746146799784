/* eslint-disable @typescript-eslint/no-explicit-any */
export interface PackageStatsProps {
    x: string;
    y: number;
}

export const FormatPackageStats = ({ data }: any): PackageStatsProps[] => {
    const _stats = data.map(
        (data: any): PackageStatsProps => {
            return {
                x: `${data.RaisonSociale}:${Number(data.ratio).toFixed(1)}%`,
                y: +data.ratio,
            };
        },
    );

    return _stats;
};

export const PackageStatsBuilder = (): PackageStatsProps[] => [
    {
        x: '',
        y: 0,
    },
];

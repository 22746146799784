import React from 'react';
import { FunctionComponent } from 'react';

interface CheckboxProps {
    label: string;
    name: string;
    id: string;
    register?: React.LegacyRef<HTMLInputElement>;
}

const CheckBox: FunctionComponent<CheckboxProps> = ({ label, name, id, register }) => {
    return (
        <label htmlFor={id} className="flex py-2 ml-5">
            <input id={id} type="checkbox" className="text-base mr-2" name={name} ref={register} />
            {label}
        </label>
    );
};

export default CheckBox;

import { Column } from 'react-table';
import { Link, useParams } from 'react-router-dom';
import { FaFile, FaKey, FaPeopleCarry } from 'react-icons/fa';
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import Icon from 'components/atoms/Icon';
import Card from 'components/molecules/Card';
import TextDeco from 'components/atoms/Text';
import Button from 'components/atoms/Buttons';
import Select from 'components/atoms/Form/Select';
import SelectEtat from 'components/molecules/Inputs/Select';
import Table, { RefTable } from 'components/organisms/Table/ReactTable';
import Notification from 'components/atoms/Notifications';
import classNames from 'classnames';

import API from 'constants/API';
import Routes from 'constants/Routes';
import { GET, PATCH, POST } from 'utils/Fetch';
import { FormatSelectUser } from 'models/UsersBuilder/UserBuilder';
import { NotificationContext } from 'contexts/Notifications';
import { DeliveriesHistory, FormatDelivery, DeliveryBuilder } from 'models/DeliveryBuilder';
import useOptions from 'utils/Hook/GetOptions';
import dayjs from 'dayjs';

interface Options {
    label: string;
    value: string;
}

const Delivery = () => {
    const { id } = useParams<{ id: string }>();
    const { state, dispatch } = useContext(NotificationContext);
    const [delivery, setDelivery] = useState(DeliveryBuilder());
    const [showSelect, setShowSelect] = useState<boolean>(false);
    const [status, setStatus] = useState<Options>({ label: '', value: '' });
    const { statusOptions: statusOption } = useOptions({ getStatus: 'livraison' });
    const [userList, setUserList] = useState<{ label: string; value: string }[]>([{ label: '', value: '' }]);
    const componentRef = useRef(null);

    const GetDelivery = async () => {
        const { status, message, ...response } = await GET(API.DELIVERY + id);
        if (!status) return dispatch({ message, status });
        setDelivery(FormatDelivery(response));
    };

    const GetUserList = async () => {
        const { status, message, ...response } = await GET(API.USER_LIST);
        if (!status) return dispatch({ message, status });
        setUserList(FormatSelectUser(response));
    };

    useEffect(() => {
        GetDelivery();
        GetUserList();
    }, []);

    const ChangeDeliver = async (data: string) => {
        const body = {
            fk_livreur: +data,
            id: +delivery.id,
        };

        const { status, message } = await PATCH(API.DELIVERY_CHANGE_DELIVER, body);
        dispatch({ status, message });
    };

    const onSubmit = async (data: Options) => {
        const body = {
            fk_etat: data.value,
            fk_livraison: id,
        };
        const { status, message } = await POST(API.DELIVERY_CHANGE_ETAT, body);
        if (!status) return dispatch({ status, message });
        dispatch({ message, status });
        GetDelivery();
        setShowSelect(false);
    };

    const reactToPrintContent = React.useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const handlePrint = useReactToPrint({
        content: reactToPrintContent,
        documentTitle: `Livraison ${delivery.id}`,
    });
    return (
        <section className="container mx-auto my-5 px-2 grid grid-cols-1 lg:grid-cols-6 gap-5">
            <div className="col-span-4 lg:col-span-2 row-start-2">
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info={`Ajouté le ${delivery.createAt}`}>
                    Livraison {delivery.id}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaFile} info="Tournée">
                    {delivery.tourLabel}
                </Card>
                <Card className="lg:col-span-2 lg:col-start-1" icon={FaPeopleCarry} info="Livreur">
                    <Select
                        name="deliverer"
                        onChange={({ target }) => ChangeDeliver(target.value)}
                        options={userList}
                        value={delivery.delivererId}
                    />
                </Card>
            </div>
            <Card className="col-span-4 row-start-2 md:row-start-2 lg:col-start-3 lg:col-span-5 flex flex-col">
                <small>Historique</small>
                <Table columns={columns} data={delivery.deliveriesHistory} />
            </Card>
            <Button onClick={handlePrint} variant="outline" className="col-span-1 row-start-3 md:row-start-3">
                imprimer
            </Button>
            <div className="col-span-6 row-start-4 md:row-start-4">
                <RefTable ref={componentRef} columns={DocumentList} data={delivery.documentsList} />
            </div>
            <div className=" lg:col-start-5 row-start-1 lg:col-span-2 ">
                <Notification message={state.message} status={state.status} />
                {showSelect && (
                    <>
                        <SelectEtat
                            name="etat"
                            option={statusOption}
                            onChange={(e: Options) => setStatus(e)}
                            className="py-2"
                            isMulti={false}
                        />
                        <Button
                            type="submit"
                            className="w-full flex items-center justify-center"
                            variant="secondary"
                            onClick={() => onSubmit(status)}
                            bold
                        >
                            Appliquer le changement
                        </Button>
                    </>
                )}
                <Button
                    type="button"
                    className="w-full flex items-center justify-center mt-5"
                    onClick={() => setShowSelect(!showSelect)}
                    bold
                >
                    {showSelect ? <p>Ne pas changer l&apos;état</p> : <p>Changer l&apos;état</p>}
                </Button>
            </div>
        </section>
    );
};

const CustomCell = (props: {
    value: string;
    row: { original: { status_color: 'success' | 'secondary' | 'info' | 'warning' | 'none' | 'error' } };
}) => (
    <Fragment>
        <TextDeco text={props.value} color={props.row.original.status_color} />
    </Fragment>
);

const DeliveryCell = (props: { value: string; row: { original: { documentId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DOCUMENTS}/${props.row.original.documentId}`}>
            <p className="text-sm w-auto text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const TiersSource = (props: { value: string; row: { original: { tiersDestId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersDestId}`}>
            <p className="text-sm w-auto text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);
const HasKeys = (props: { value: string; row: { original: { numeroKey: string; couleurEtatKey: string } } }) => (
    <Fragment>
        {props.row.original.numeroKey && (
            <span className="flex justify-center">
                {props.row.original.numeroKey}
                {props.row.original.couleurEtatKey && (
                    <Icon
                        Icon={FaKey}
                        className={classNames('text-sm  ml-1', {
                            'text-green-500': props.row.original.couleurEtatKey === 'success',
                            'text-blue-500': props.row.original.couleurEtatKey === 'info',
                            'text-red-500': props.row.original.couleurEtatKey === 'error',
                        })}
                    />
                )}
            </span>
        )}
    </Fragment>
);

const columns: Column<DeliveriesHistory>[] = [
    {
        Header: 'Date',
        accessor: 'createAt',
        Cell: (props: { value: string; row: { original: { deliveryId: string } } }) => (
            <span>{dayjs(props.value).format('DD/MM/YYYY HH:mm')}</span>
        ),
    },
    {
        Header: 'État',
        accessor: 'statusLabel',
        Cell: CustomCell,
    },

    {
        Header: 'Livreur',
        accessor: 'delivererName',
    },
];

const DocumentList: Column<DeliveriesHistory>[] = [
    {
        Header: 'TiersSource',
        accessor: 'tiersSourceName',
    },
    {
        Header: 'TiersDestination',
        accessor: 'tiersDestName',
        Cell: TiersSource,
    },
    {
        Header: 'Horaires',
        accessor: 'openingHours',
    },
    {
        Header: 'Clé',
        accessor: 'hasKeys',
        Cell: HasKeys,
    },
    {
        Header: "Heure d'arrivée",
        accessor: 'arrivalTime',
    },
    {
        Header: 'Adresse',
        accessor: 'tiersDestAddress',
    },
    {
        Header: 'Document',
        accessor: 'documentNumber',
        Cell: DeliveryCell,
    },
    {
        Header: 'État',
        accessor: 'statusLabel',
        Cell: CustomCell,
    },
    {
        Header: 'Colis',
        accessor: 'countPackage',
    },
];

export default Delivery;

/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatDate from 'utils/FormatDate';

///// FORM /////
export interface Close {
    id: string;
    companyId: string;
    companyName: string;
    endDate: Date;
    startDate: Date;
    comment: string;
}

export const EmptyCloseData: Close = {
    id: '',
    companyId: '',
    companyName: '',
    endDate: new Date(),
    startDate: new Date(),
    comment: '',
};

/** ReFormatData for BackEnd */
export const ReFormatClose = (data: Close) => ({
    id: +data.id !== 0 ? +data.id : null,
    commentaire: data.comment,
    end_date: FormatDate(data.endDate),
    start_date: FormatDate(data.startDate),
    fk_tiers: +data.companyId,
});

///// Format Data /////

export interface Closure extends Close {
    login: string;
    companyName: string;
    address: string;
}

export const FormatClosureList = ({ data }: any): Closure[] =>
    data.map(
        (elem: any): Closure => ({
            login: elem.Identifiant,
            companyName: elem.RaisonSociale,
            comment: elem.commentaire,
            address: elem.concatAdresse,
            endDate: elem.end_date,
            companyId: elem.fk_tiers,
            id: elem.id,
            startDate: elem.start_date,
        }),
    );

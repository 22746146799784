import React, { FunctionComponent } from 'react';
import classNames from 'classnames';

interface TitleProps {
    text: string;
    noBar?: boolean;
}
const Title: FunctionComponent<TitleProps> = ({ text, noBar }) => (
    <div className="mb-2">
        <p className={classNames('text-2xl text-purple-900 p-0')}>{text}</p>
        {!noBar && <hr className="border-purple-900 border-2 w-4/5" />}
    </div>
);

export default Title;

import { Link } from 'react-router-dom';
import { FaSort, FaTimes } from 'react-icons/fa';
import { useDrag, useDrop } from 'react-dnd';
import React, { FunctionComponent } from 'react';

import Icon from 'components/atoms/Icon';

import Routes from 'constants/Routes';
import { TourOrder } from 'models/TourBuilder';

export interface RowProps extends TourOrder {
    id: string;
    moveCard: (id: string, to: number) => void;
    findCard: (id: string) => { index: number };
    setUpdate: () => Promise<void>;
    deleteCard: (id: number) => void;
}

interface Item {
    id: string;
    originalIndex: number;
}

const DragableRow: FunctionComponent<RowProps> = ({
    id,
    order,
    tiersId,
    name,
    address,
    moveCard,
    findCard,
    setUpdate,
    deleteCard,
}) => {
    const originalIndex = findCard(id).index;
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: 'card',
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
            end: (item, monitor) => {
                const { id: droppedId, originalIndex } = item;
                const didDrop = monitor.didDrop();
                if (!didDrop) moveCard(droppedId, originalIndex);

                setUpdate();
            },
        }),
        [id, originalIndex, moveCard],
    );

    const [, drop] = useDrop(
        () => ({
            accept: 'card',
            canDrop: () => false,
            hover({ id: draggedId }: Item) {
                if (draggedId !== id) {
                    const { index: overIndex } = findCard(id);
                    moveCard(draggedId, overIndex);
                }
            },
        }),
        [findCard, moveCard],
    );

    const opacity = isDragging ? 0 : 1;
    return (
        <div className="flex items-center justify-between text-sm h-8 mb-2" style={{ opacity }}>
            <span
                ref={(node) => drag(drop(node))}
                className="flex items-center cursor-move h-full rounded-l-md border border-purple-500 bg-purple-500 hover:bg-purple-300"
            >
                <Icon Icon={FaSort} className="text-base text-white" />
            </span>
            <div className="flex items-center justify-between border border-purple-400 text-sm w-full h-8 px-2 hover:text-blue-600">
                <span className="flex items-center w-4/12">
                    <Link to={Routes.COMPANY + '/' + tiersId}>
                        <span className="text-sm text-purple-900 hover:underline">{name}</span>
                    </Link>
                </span>
                <span className="flex items-center w-7/12">{address}</span>
                <span className="flex items-center">{order}</span>
            </div>
            <span
                onClick={() => deleteCard(originalIndex)}
                className="flex items-center h-full rounded-r-md border border-red-500 bg-red-500 hover:bg-red-300"
            >
                <Icon Icon={FaTimes} className="text-base text-white" />
            </span>
        </div>
    );
};

export default DragableRow;

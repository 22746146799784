import { FaHistory, FaFileExport } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';

import { colisColumns } from './Cells';
import Button from 'components/atoms/Buttons';
import DatePicker from 'components/molecules/Inputs/DatePicker';
import HistogramModal from 'components/organisms/Modal/Histogram';
import Table from 'components/organisms/Table/ReactTable/AsyncTable';
import DropdownMultipleSelect from 'components/molecules/MultiSelect';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { queries } from 'utils/CreateQueries/StackStatistic';
import { APItoFront, BackAllParams, column, PARAMS } from 'utils/CreateQueries';
import { FormatTiersStats, StatisticBuilder, TiersStatsProps } from 'models/StatisticBarBuilder';
import PackagesListProps, { FormatPackagesList, PackagesListBuilder } from 'models/PackagesListBuilder';
import useOptions from 'utils/Hook/GetOptions';
import Loading from 'components/organisms/Modal/Loading';
import { getPreviousDay } from 'utils/FormatDate';
import { formatDate } from '@fullcalendar/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryToState, QueryToString } from 'utils/CreateQueries/query';

const PackagesList = () => {
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [, setSearchParams] = useSearchParams();
    const search = useLocation().search;
    const [filter, setFilter] = useState<PARAMS>(params);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [arrayLoading, setArrayLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [HistogramInfo, setHistogramInfo] = useState<TiersStatsProps[]>(StatisticBuilder());
    const [packagesList, setPackageList] = useState<PackagesListProps[]>(PackagesListBuilder());
    const { companySourceOptions, statusOptions: statusOption } = useOptions({
        getCompanies: true,
        getStatus: 'colis',
    });

    const UpdateFromQuery = () => {
        const newQ = QueryToState(search);
        const localColumn: string = newQ.columnToOrder;
        const filterState = {
            pageIndex: newQ.numPage ? +newQ.numPage : +filter.pageIndex,
            pageSize: newQ.nbrElements ? +newQ.nbrElements : +filter.pageSize,
            sortBy: [{ id: APItoFront[localColumn], desc: !newQ.isAscendingOrder }],
            startDate: newQ.startDate ? newQ.startDate : filter.startDate,
            endDate: newQ.endDate ? newQ.endDate : filter.endDate,
            status: [],
            tiers: [],
            globalFilter: newQ.search ? newQ.search : filter.globalFilter,
        };

        if (newQ.fk_etat) {
            const fkStatus = newQ.fk_etat.map((elem: string[]) => statusOption.find((e) => +e.value === +elem));
            filterState.status = fkStatus;
        }

        if (newQ.fk_tiersSource) {
            const fkTiersSource = newQ.fk_tiersSource.map((elem: string[]) =>
                companySourceOptions.find((e) => +e.value === +elem),
            );
            filterState.tiers = fkTiersSource;
        }

        setFilter(filterState);
        GetPackagesList(search);
    };

    useEffect(() => {
        if (statusOption.length > 0) UpdateFromQuery();
    }, [statusOption]);

    const GetPackagesList = async (queries: string) => {
        const { status, nbTotalRows, ...response } = await GET(API.PACKAGES_LIST + queries);
        if (!status) return;
        setPackageList(FormatPackagesList(response));
        setTotalRows(nbTotalRows);
        setPageCount(Math.round(nbTotalRows / filter.pageSize));
        setPageLoading(false);
        setArrayLoading(false);
    };

    const ExportExcel = async () => {
        const tiersId = filter.tiers.map((d) => ({ id: +d.value }));
        const _queries = queries({
            isDistributor: 1,
            excel: '1',
            startDate: new Date(filter.startDate),
            endDate: new Date(filter.endDate),
            tsId: tiersId,
        });
        const response = await GET(`${API.PACKAGE_STATS_BAR}${_queries}`);
        FileSaver.saveAs(response, 'Colis.xls');
    };

    const GetAllTiersStats = async () => {
        const idTiers = filter.tiers.map((item) => ({ id: +item.value, name: item.label }));
        const StatsQueries = queries({
            isDistributor: 1,
            excel: '0',
            tsId: idTiers,
            endDate: new Date(filter.endDate),
            startDate: new Date(filter.startDate),
        });
        const { status, ...response } = await GET(`${API.PACKAGE_STATS_BAR}${StatsQueries}`);
        if (!status) return;
        setHistogramInfo(FormatTiersStats(response));
        setOpenModal(true);
    };

    const StateToQuery = (filter: any) => {
        setArrayLoading(true);
        const BackQuery: BackAllParams = {
            nbrElements: filter.pageSize,
            numPage: filter.pageIndex,
            search: !filter.globalFilter ? '' : filter.globalFilter,
            isAscendingOrder: filter.sortBy[0].desc ? 0 : 1,
            columnToOrder: column[filter.sortBy[0].id],
            fk_etat: filter.status,
            fk_tiersSource: filter.tiers,
            endDate: filter.endDate,
            startDate: filter.startDate,
        };
        setSearchParams(QueryToString(BackQuery));
        GetPackagesList(QueryToString(BackQuery));
    };

    const SETFILTER = (newParams: any) => {
        const includeUpdateList = ['pageSize', 'sortBy', 'pageIndex'];
        if (includeUpdateList.includes(Object.keys(newParams)[0])) {
            StateToQuery({ ...filter, ...newParams });
        }
        setFilter((curr: any) => ({
            ...curr,
            ...newParams,
            pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0,
        }));
    };
    if (pageLoading) return <Loading />;

    return (
        <>
            <section className="mx-10 my-5">
                <div className="flex justify-center items-center">
                    <div className="w-1/2 flex justify-start">
                        {/* <h2 className="text-2xl font-semibold">Colis</h2> */}
                    </div>
                    <div className="w-1/2 flex">
                        <Button icon={FaFileExport} variant="outline" bold onClick={() => ExportExcel()}>
                            Export Excel
                        </Button>
                        <Button icon={FaHistory} onClick={() => GetAllTiersStats()} variant="outline" bold>
                            Histogramme
                        </Button>
                    </div>
                </div>
                <form id="DocumentSearch" className="flex w-full">
                    <DatePicker
                        onChange={(e: Date) =>
                            SETFILTER({
                                startDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-'),
                            })
                        }
                        selected={new Date(filter.startDate)}
                        label="Date de début"
                    />
                    <DatePicker
                        onChange={(e: Date) =>
                            SETFILTER({
                                endDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-'),
                            })
                        }
                        selected={new Date(filter.endDate)}
                        label="Date de fin"
                    />
                    <DropdownMultipleSelect
                        label="Sélection du tiers source"
                        onSelect={(value) =>
                            SETFILTER({
                                tiers: value,
                            })
                        }
                        defaultValue={filter.tiers}
                        items={companySourceOptions}
                    />
                    <DropdownMultipleSelect
                        label="Sélection de l'état"
                        onSelect={(value) =>
                            SETFILTER({
                                status: value,
                            })
                        }
                        defaultValue={filter.status}
                        items={statusOption}
                    />
                </form>
                <div className="flex justify-end">
                    <Button
                        onClick={() => [StateToQuery({ ...filter, pageIndex: 0 }), setPageLoading(true)]}
                        variant="default"
                    >
                        Rechercher...
                    </Button>
                </div>
                <Table
                    columns={colisColumns}
                    data={packagesList}
                    pageCount={pageCount}
                    loading={arrayLoading}
                    defaultParams={filter}
                    SETFILTER={SETFILTER}
                    nbTotalRows={totalRows}
                />
                <HistogramModal open={openModal} setOpen={setOpenModal} HistogramInfo={HistogramInfo} />
            </section>
        </>
    );
};

const params: PARAMS = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [{ id: 'documentNumber', desc: false }],
    startDate: formatDate(getPreviousDay(), { locale: 'fr' }).split('/').reverse().join('-'),
    endDate: formatDate(new Date(), { locale: 'fr' }).split('/').reverse().join('-'),
    status: [],
    tiers: [],
    globalFilter: '',
};

export default PackagesList;

import React, { FunctionComponent, useEffect, useState } from 'react';

import Histogram from 'components/organisms/Statistic/Histogram';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { queries } from 'utils/CreateQueries/StackStatistic';
import { FormatTiersStats, StatisticBuilder, TiersStatsProps } from 'models/StatisticBarBuilder';

interface TiersStats {
    id: string;
    isDistributor: number;
}
const TiersStats: FunctionComponent<TiersStats> = ({ id, isDistributor }) => {
    const [statistic, setStatistic] = useState<TiersStatsProps[]>(StatisticBuilder());

    const GetTiersStatistic = async () => {
        const StatsQueries = queries({
            isDistributor,
            excel: '0',
            tsId: [{ id: +id }],
        });
        const { status, ...response } = await GET(`${API.PACKAGE_STATS_BAR}${StatsQueries}`);

        if (!status) return;
        setStatistic(FormatTiersStats(response));
    };

    useEffect(() => {
        GetTiersStatistic();
    }, []);

    return (
        <section className="w-full">
            <Histogram data={statistic} />
        </section>
    );
};

export default TiersStats;

import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface ValidProps {
    type: string;
    valid: boolean;
}

const Valid: FunctionComponent<ValidProps> = ({ children, type, valid }) => {
    return (
        <div
            className={classNames('relative border-2 p-2', {
                'border-none': type === '1',
                'border-green-600': valid && type !== '1',
                'border-red-600': !valid && type !== '1',
            })}
        >
            <span
                className={classNames('absolute top-0 right-0 px-2', {
                    hidden: type === '1',
                    'bg-green-600': valid && type !== '1',
                    'bg-red-600': !valid && type !== '1',
                })}
            >
                {valid ? 'Valide' : 'Invalide'}
            </span>
            {children}
        </div>
    );
};

export default Valid;

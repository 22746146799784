import { Link } from 'react-router-dom';
import React, { Fragment, useContext, useEffect, useState } from 'react';

import Button from 'components/atoms/Buttons';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import API from 'constants/API';
import { DELETE, GET } from 'utils/Fetch';
import Routes from 'constants/Routes';
import ListOfUserProps, { FormatUserList } from 'models/UsersBuilder/UsersList';
import AbsentModal from './UsersCalendar/absentModal';
import { FaTrash } from 'react-icons/fa';
import ConfirmDelete from 'pages/CompaniesList/ConfirmDelete';
import { NotificationContext } from 'contexts/Notifications';
// import { AuthenticationContext } from 'contexts/Authentication';
import UserProps from 'models/UsersBuilder/UserBuilder';

const Users = () => {
    const { dispatch } = useContext(NotificationContext);
    // const { authentication, setAuthentication } = useContext(AuthenticationContext);

    const [usersList, setUsersList] = useState<ListOfUserProps[]>([]);
    const [absentModal, setAbsentModal] = useState<boolean>(false);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);
    const [userId, setUserId] = useState<string>('');

    const GetUserList = async () => {
        const { status, ...response } = await GET(API.USER_LIST);
        if (status) setUsersList(FormatUserList(response));
    };

    const DeleteUser = async (id: string) => {
        const { status, message } = await DELETE(API.USER_PROFIL + '/' + id);
        dispatch({ status, message });
        setConfirmDeleteModal(false);
        GetUserList();
    };

    // const Usurpation = async (user: any) => {
    //     const pr = user.rolesLabel.split(',');
    //     setAuthentication((curr) => ({
    //         ...curr,
    //         roleLabel: {
    //             user: curr.roleLabel.user,
    //             usurpation: [...pr],
    //         },
    //     }));
    // };

    useEffect(() => {
        GetUserList();
    }, []);

    const IdCell = (props: { column: { Header: string }; value: string; row: { original: UserProps } }) => (
        <Fragment>
            <span className="flex justify-between">
                <Link to={`${Routes.USER}/${props.row.original.id}`} className="w-full">
                    <p className="text-sm text-purple-900 hover:underline">{props.value}</p>
                </Link>
                {/* <button type="button" className="outlined-none" onClick={() => Usurpation(props.row.original)}>
                    Plop
                </button> */}
                <button
                    type="button"
                    className="outlined-none text-red-600"
                    onClick={() => {
                        setConfirmDeleteModal(true);
                        setUserId(`${props.row.original.id}`);
                    }}
                >
                    <FaTrash />
                </button>
            </span>
        </Fragment>
    );

    const columns = [
        {
            Header: 'Nom Prénom',
            accessor: 'name',
            Cell: IdCell,
            className: 'text-left border border-gray-200  px-2 w-1/3',
        },
        {
            Header: 'Role(s)',
            accessor: 'rolesLabel',
            className: 'text-left border border-gray-200  px-2  w-1/3',
        },
        {
            Header: 'Centre(s) logistique',
            accessor: 'centresLabel',
            className: 'text-left border border-gray-200  px-2  w-1/3',
        },
    ];

    return (
        <>
            <section className="container mx-auto my-5">
                <div className="flex">
                    <Link to={`${Routes.USER}/0`}>
                        <Button>Ajouter un utilisateur</Button>
                    </Link>
                    <Button variant="outline" onClick={() => setAbsentModal(true)}>
                        Créer une absence
                    </Button>
                </div>
                <Table columns={columns} data={usersList} sortBy="name" />
            </section>
            <ConfirmDelete
                open={confirmDeleteModal}
                setOpen={setConfirmDeleteModal}
                id={userId}
                Delete={DeleteUser}
                label="user"
            />
            <AbsentModal open={absentModal} setOpen={setAbsentModal} />
        </>
    );
};

export default Users;

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TourList {
    description: string;
    label: string;
    lastName: string;
    firstName: string;
    deliverName: string;
    deliverId: number;
    id: number;
    days: string;
    codeTournee: string;
    centreLogistique: string;
    horairesDuLendemain: any;
}

export interface TourListProps extends TourList {
    [key: string]: string | number | boolean;
}

export const TourListBuilder = (): TourListProps[] => [
    {
        description: '',
        label: '',
        lastName: '',
        firstName: '',
        deliverName: '',
        deliverId: 0,
        id: 0,
        days: '',
        codeTournee: '',
        centreLogistique: '',
        horairesDuLendemain: true,
    },
];

export const FormatTourList = ({ data }: any): TourListProps[] => {
    const _list = data.map(
        (data: any): TourListProps => {
            return {
                description: data.Description,
                label: data.Libelle,
                lastName: data.Nom,
                firstName: data.Prenom,
                deliverName: data.NomPrenomLivreur,
                deliverId: data.fk_livreur,
                horairesDuLendemain: data.horairesDuLendemain,
                id: data.id,
                days: data.journees,
                codeTournee: data.identifiant_tournee,
                centreLogistique: data.RaisonSociale,
            };
        },
    );

    return _list;
};

export default TourListProps;

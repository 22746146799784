import { FaTimes } from 'react-icons/fa';
import React, { FunctionComponent } from 'react';

import Button from 'components/atoms/Buttons';
import Histogram from 'components/organisms/Statistic/Histogram';

import { TiersStatsProps } from 'models/StatisticBarBuilder';

interface ModalProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    HistogramInfo: TiersStatsProps[];
}
const HistogramModal: FunctionComponent<ModalProps> = ({ open = false, setOpen, HistogramInfo }) => {
    return (
        <>
            {open && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="flex items-end flex-col relative my-6 mx-auto w-3/4">
                            <Button
                                variant="outline"
                                className="w-12 my-2"
                                icon={FaTimes}
                                onClick={() => setOpen(false)}
                            />
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col min-w-full bg-white outline-none focus:outline-none">
                                <div className="flex justify-start p-5">
                                    <Histogram data={HistogramInfo} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black" />
                </>
            )}
        </>
    );
};

export default HistogramModal;

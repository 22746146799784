export interface History {
    latLng: { lat: number; lng: number };
    pharmacyName: string;
    hoursEstimate: string;
    deliveryStatus: string;
    openingHours: string;
    email: string;
    address: string;
    phone: string;
}

export interface DeliveryList {
    adresse: string;
    codePostale: string;
    email: string;
    identifiant: string;
    latitude: number;
    longitude: number;
    openingHours: string;
    ordreLivraison: number;
    raisonSocial: string;
    ville: string;
    color: string;
    livrer: 1 | 0;
    hasKeys: 1 | 0;
    date: string;
    scanDate: string;
    scanLatitude: number;
    scanLongitude: number;
}

export interface DeliveriesMapsProps {
    tour: string;
    userId: number;
    user: string;
    deliveryId: number;
    userGpsHistory: any[];
    deliveryList: DeliveryList[];
    showList: boolean;
    color: string;
}

export const DeliveryHistory = (): DeliveriesMapsProps[] => [
    {
        tour: '',
        userId: 0,
        user: '',
        deliveryId: 0,
        userGpsHistory: [{ lat: 48.8566, lng: 2.3522 }],
        deliveryList: [],
        showList: false,
        color: 'white',
    },
];

export const colorScale = [
    '#003f5c',
    '#2f4b7c',
    '#665191',
    '#a05195',
    '#d45087',
    '#f95d6a',
    '#ff7c43',
    '#ffa600',
    '#2a9d8f',
    '#264653',
    '#354f52',
    '#6d597a',
    '#fb5607',
    '#d90429',
    '#344e41',
    '#219ebc',
    '#335c67',
    '#e09f3e',
    '#9e2a2b',
    '#540b0e',
    '#985277',
];

export const FormatListDelivere = ({ data }: any): DeliveriesMapsProps[] =>
    data.map(
        (data: any, index: number): DeliveriesMapsProps => ({
            tour: data.tournees,
            userId: data.livreurId,
            user: data.livreur,
            deliveryId: data.livraisonId,
            userGpsHistory: [],
            deliveryList: [],
            showList: false,
            color: colorScale[index],
        }),
    );

export default DeliveriesMapsProps;

import classNames from 'classnames';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import Icon from 'components/atoms/Icon';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FaAngleDown } from 'react-icons/fa';
import Link from 'components/atoms/Link';
import { UserControl } from 'utils/UserControl';
import { useAuthenticateContext } from 'contexts/Authentication';

interface subOptions {
    label: string;
    route: string;
    userRole: string[];
}
interface NavigationProps {
    navigationLink: {
        id: string;
        title: string;
        route: string;
        icon: IconType;
        userRole: string[];
        subRoute?: subOptions[];
    }[];
    close: () => void;
}

const Navigation: FunctionComponent<NavigationProps> = ({ navigationLink, close }) => {
    const { authentication } = useAuthenticateContext();
    const { pathname } = useLocation();
    const [isDown, setIsDown] = useState('');

    const ref: React.ForwardedRef<HTMLDivElement> = useRef(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClickOutside = (event: { target: any }) => {
            if (ref.current) {
                if (!ref.current.contains(event.target)) {
                    setIsDown('');
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
        <ul className="flex flex-col xl:flex-row">
            {navigationLink.map((nav, index) => {
                if (UserControl(authentication.roleLabel, nav.userRole) === false) return;

                return (
                    <li key={index} className="flex items-center">
                        <div className="relative">
                            <div
                                onClick={() => setIsDown(nav.id)}
                                className={classNames(
                                    'flex items-center hover:text-emerald-600 text-xl px-3 cursor-pointer',
                                    {
                                        'text-green-500 hover:text-green-500': pathname.includes(
                                            nav.route.split('?')[0],
                                        ),
                                        'text-gray-300 hover:text-gray-100': !pathname.includes(
                                            nav.route.split('?')[0],
                                        ),
                                    },
                                )}
                            >
                                {nav.icon && <Icon Icon={nav.icon} className="w-7 mr-3 xl:mr-1" />}
                                {nav.subRoute === undefined ? (
                                    <Link
                                        className={classNames('py-3')}
                                        to={nav.route}
                                        onClick={() => close()}
                                        icon={nav.icon}
                                    >
                                        {nav.title}
                                    </Link>
                                ) : (
                                    <p>{nav.title}</p>
                                )}
                                {nav.subRoute && <Icon Icon={FaAngleDown} className="w-7" />}
                            </div>
                            {isDown === nav.id && nav.subRoute && (
                                <div
                                    ref={ref}
                                    className="bg-white absolute top-10 left-4 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                                    style={{ minWidth: '12rem' }}
                                >
                                    {nav.subRoute.map((option: subOptions, j: number) => {
                                        if (UserControl(authentication.roleLabel, option.userRole) === false) return;
                                        return (
                                            <Link
                                                key={j}
                                                className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-purple-800 hover:bg-purple-700 hover:text-white"
                                                to={option.route}
                                                onClick={() => [setIsDown(''), close()]}
                                            >
                                                {option.label}
                                            </Link>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default Navigation;

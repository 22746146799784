import React, { FunctionComponent, useState } from 'react';
import { useCombobox, useMultipleSelection } from 'downshift';

interface Items {
    label: string;
    value: string;
}
interface Props {
    items: Items[];
    defaultValue: Items[];
    label: string;
    onSelect: (a: any) => void;
}
const DropdownMultipleCombobox: FunctionComponent<Props> = ({ items, defaultValue, label, onSelect }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [width, setWidth] = useState<number>(58);
    const {
        getSelectedItemProps,
        getDropdownProps,
        addSelectedItem,
        removeSelectedItem,
        selectedItems,
    } = useMultipleSelection<Items>({
        onSelectedItemsChange: ({ selectedItems }) => onSelect(selectedItems),
        initialSelectedItems: defaultValue,
    });

    const getFilteredItems = (items: Items[]) =>
        items.filter(
            (item) => selectedItems.indexOf(item) < 0 && item.label.toLowerCase().startsWith(inputValue.toLowerCase()),
        );

    const {
        isOpen,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getComboboxProps,
        getItemProps,
        selectItem,
    } = useCombobox<Items | null>({
        inputValue,
        items: getFilteredItems(items),
        onStateChange: ({ inputValue, type, selectedItem }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputChange:
                    if (!inputValue) return setInputValue('');
                    setInputValue(inputValue);
                    break;
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                case useCombobox.stateChangeTypes.InputBlur:
                    if (selectedItem) {
                        setInputValue('');
                        addSelectedItem(selectedItem);
                        selectItem(null);
                    }

                    break;
                default:
                    break;
            }
        },
    });

    return (
        <div className="flex flex-col justify-center items-start px-2 mb-2 w-full">
            <label className="pb-1 flex flex-col text-sm text-gray-400 max-h-full" {...getLabelProps()}>
                {label}
            </label>
            <div className="w-full relative">
                <div
                    {...getToggleButtonProps()}
                    className="flex items-center  border border-gray-300 focus-within:border-purple-700 cursor-text  bg-white justify-between w-full px-1"
                >
                    <div className="flex flex-wrap" {...getComboboxProps()}>
                        {selectedItems.map((selectedItem, index) => (
                            <span className="flex items-center m-[0.15rem]" key={`selected-item-${index}`}>
                                <span
                                    className="flex items-center text-sm p-1 bg-gray-200 cursor-default"
                                    {...getSelectedItemProps({ selectedItem, index })}
                                >
                                    {selectedItem?.label}
                                </span>
                                <span
                                    className="hover:bg-orange-400 hover:text-orange-600 bg-gray-200 text-sm p-1 cursor-pointer"
                                    onClick={() => removeSelectedItem(selectedItem)}
                                >
                                    &#10005;
                                </span>
                            </span>
                        ))}
                        <input
                            style={{ width }}
                            onKeyPress={() => setWidth(width + 8)}
                            className="outline-none p-1 h-full"
                            placeholder={selectedItems.length > 0 ? '' : 'Choisir'}
                            {...getInputProps(getDropdownProps({ preventKeyAction: isOpen }))}
                        />
                    </div>
                    <button
                        className="flex rounded-r-sm justify-center items-center px-2 border-gray-300 h-9"
                        type="button"
                        aria-label={'toggle menu'}
                    >
                        &#8595;
                    </button>
                </div>
                <ul className="absolute bg-white w-full z-50" {...getMenuProps()}>
                    {isOpen &&
                        getFilteredItems(items).map((item, index) => (
                            <li
                                className="p-2 max-h-72"
                                style={highlightedIndex === index ? { backgroundColor: '#bde4ff' } : {}}
                                key={`${item.label}${index}`}
                                {...getItemProps({ item, index, onClick: () => setWidth(58) })}
                            >
                                {item.label}
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};

export default DropdownMultipleCombobox;

import Button from 'components/atoms/Buttons';
import Table from 'components/organisms/Table/ReactTable/SyncTable';
import API from 'constants/API';
import { NotificationContext } from 'contexts/Notifications';
import { Close, Closure, EmptyCloseData, FormatClosureList } from 'models/CompanyBuilder/CompanyClosure';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DELETE, GET } from 'utils/Fetch';
import CloseForm from '../CloseForm';
import { FaTrash, FaRegEdit } from 'react-icons/fa';
import Routes from 'constants/Routes';
import ConfirmDelete from '../ConfirmDelete';

const ClosureList = () => {
    const { dispatch } = useContext(NotificationContext);

    const [ClosureList, setClosureList] = useState<Closure[]>([]);
    const [ClosureModal, setClosureModal] = useState<boolean>(false);
    const [comfirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [companyId, setCompanyId] = useState<string>('');
    const [companyClosure, setCompanyClosure] = useState<Close>(EmptyCloseData);

    const GetCloseList = async () => {
        const { status, message, ...response } = await GET(API.COMPANY_CLOSED_LIST);
        if (!status) return dispatch({ status, message });
        setClosureList(FormatClosureList(response));
    };

    useEffect(() => {
        if (!ClosureModal && !comfirmDelete) GetCloseList();
    }, [ClosureModal, comfirmDelete]);

    const DeleteClosure = async (id: string) => {
        const { status, message } = await DELETE(`${API.COMPANY_CLOSURE}/${id}`);
        dispatch({ status, message });
        setConfirmDelete(false);
    };

    const IdCell = (props: { column: { Header: string }; value: string; row: { original: Closure } }) => (
        <Fragment>
            <>
                <span className="flex justify-between">
                    <Link to={`${Routes.COMPANY}/${props.row.original.companyId}`} className="w-full">
                        <p className="text-sm text-purple-900 hover:underline">
                            {props.value}
                            <span className="text-gray-500 italic text-xs ml-1">({props.row.original.login})</span>
                        </p>
                    </Link>
                    <button
                        type="button"
                        className="outlined-none text-red-600  px-1"
                        onClick={() => {
                            setConfirmDelete(true);
                            setCompanyId(props.row.original.id);
                        }}
                    >
                        <FaTrash />
                    </button>
                    <button
                        type="button"
                        className="outlined-none text-purple-600 px-1 text-lg"
                        onClick={() => {
                            setClosureModal(true);
                            setCompanyClosure({
                                ...props.row.original,
                                endDate: new Date(props.row.original.endDate),
                                startDate: new Date(props.row.original.startDate),
                            });
                        }}
                    >
                        <FaRegEdit />
                    </button>
                </span>
                <span className="text-gray-400 italic text-xs">{props.row.original.address}</span>
            </>
        </Fragment>
    );

    const columns = [
        {
            Header: 'Pharmacie',
            accessor: 'companyName',
            className: 'text-left border border-gray-200  px-2 w-1/3',
            Cell: IdCell,
        },
        {
            Header: 'Début',
            accessor: 'startDate',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Fin',
            accessor: 'endDate',
            className: 'text-left border border-gray-200  px-2',
        },
        {
            Header: 'Commentaire',
            accessor: 'comment',
            className: 'text-left border border-gray-200  px-2',
        },
    ];

    return (
        <section className="container mx-auto p-5">
            <Button onClick={() => setClosureModal(true)}>Créer une fermeture</Button>
            <Table columns={columns} data={ClosureList} sortBy="pharmacy" />
            <CloseForm
                open={ClosureModal}
                setOpen={setClosureModal}
                closure={companyClosure}
                setClosure={setCompanyClosure}
            />
            <ConfirmDelete
                open={comfirmDelete}
                setOpen={setConfirmDelete}
                id={companyId}
                Delete={DeleteClosure}
                label="closure"
            />
        </section>
    );
};

export default ClosureList;

import { Months } from 'constants/Options';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface Stats {
    x: string;
    y: number;
    year: string;
    label: string;
}
export interface TiersStatsProps {
    id: number;
    tName: string;
    stats: Stats[];
}

export const StatisticBuilder = (): TiersStatsProps[] => [
    {
        id: 0,
        tName: '',
        stats: [
            {
                x: '',
                y: 0,
                year: '',
                label: '',
            },
        ],
    },
];

export const FormatTiersStats = ({ data }: any) => {
    let labeltsName = false;
    if (data.length >= 2) labeltsName = true;

    const _tiers = data.map((data: any) => {
        const _stats = data.stats.map((stats: any) => ({
            x: `${Months[+stats.month - 1]}-${stats.year}`,
            y: stats.nbColis,
            year: stats.year,
            label: labeltsName ? `${data.raisonSociale}\n \n colis:${stats.nbColis}` : stats.nbColis,
        }));
        return {
            id: data.id,
            tName: data.raisonSociale,
            stats: _stats,
        };
    });

    return _tiers;
};

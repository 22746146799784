import React, { FunctionComponent } from 'react';
import { IconType } from 'react-icons/lib';
import classNames from 'classnames';

interface IconProps {
    className?: string;
    Icon: IconType;
    onClick?: () => void;
}

const Icon: FunctionComponent<IconProps> = ({ Icon, className, onClick }) => {
    return <Icon className={classNames('w-4', className)} onClick={onClick} />;
};

export default Icon;

import { BsTagFill } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';

import Select from 'components/molecules/Inputs/Select';
import DatePicker from 'components/molecules/Inputs/DatePicker';
import Table from 'components/organisms/Table/ReactTable/AsyncTable';

import API from 'constants/API';
import { GET } from 'utils/Fetch';
import { deliveryColumn } from './Cells';
import { APItoFront, BackParams, column, Options, PARAMS } from 'utils/CreateQueries';
import DeliveriesListProps, { FormatDeliveriesList } from 'models/DeliveriesListBuilder';
import useOptions from 'utils/Hook/GetOptions';
import { formatDate } from '@fullcalendar/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { QueryToState, QueryToString } from 'utils/CreateQueries/query';
import { getPreviousDay } from 'utils/FormatDate';
import Loading from 'components/organisms/Modal/Loading';

const DeliveryList = () => {
    const { statusOptions } = useOptions({ getStatus: 'livraison' });
    const [filter, setFilter] = useState<PARAMS>(params);
    const [pageCount, setPageCount] = useState<number>(0);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [, setSearchParams] = useSearchParams();
    const search = useLocation().search;

    const [devilveriesList, setDeliveriesList] = useState<DeliveriesListProps[]>([]);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [arrayLoading, setArrayLoading] = useState<boolean>(true);

    const UpdateFromQuery = () => {
        const newQ = QueryToState(search);
        const localColumn: string = newQ.columnToOrder;
        const filterState = {
            pageIndex: newQ.numPage ? +newQ.numPage : +filter.pageIndex,
            pageSize: newQ.nbrElements ? +newQ.nbrElements : +filter.pageSize,
            sortBy: [{ id: APItoFront[localColumn], desc: !newQ.isAscendingOrder }],
            startDate: newQ.startDate ? newQ.startDate : filter.startDate,
            endDate: newQ.endDate ? newQ.endDate : filter.endDate,
            status: [],
            tiers: [],
            globalFilter: newQ.search ? newQ.search : filter.globalFilter,
        };

        if (newQ.fk_etat) {
            const fkStatus = newQ.fk_etat.map((elem: string[]) => statusOptions.find((e) => +e.value === +elem));
            filterState.status = fkStatus;
        }

        setFilter(filterState);
        GetDeliveryList(search);
    };

    useEffect(() => {
        if (statusOptions.length > 0) UpdateFromQuery();
    }, [statusOptions]);

    const GetDeliveryList = async (queries: string) => {
        const { status, nbTotalRows, ...response } = await GET(API.DELIVERIES_LIST + queries);
        if (!status) return;
        setDeliveriesList(FormatDeliveriesList(response));
        setPageCount(Math.round(nbTotalRows / filter.pageSize));
        setTotalRows(nbTotalRows);
        setPageLoading(false);
        setArrayLoading(false);
    };

    const StateToQuery = (filter: any) => {
        setArrayLoading(true);
        const BackQuery: BackParams = {
            nbrElements: filter.pageSize,
            numPage: filter.pageIndex,
            search: !filter.globalFilter ? '' : filter.globalFilter,
            isAscendingOrder: filter.sortBy[0].desc ? 0 : 1,
            columnToOrder: column[filter.sortBy[0].id],
            fk_etat: filter.status,
            endDate: filter.endDate,
            startDate: filter.startDate,
        };
        setSearchParams(QueryToString(BackQuery));
        GetDeliveryList(QueryToString(BackQuery));
    };

    const SETFILTER = (newParams: any) => {
        setFilter((curr: any) => ({
            ...curr,
            ...newParams,
            pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0,
        }));
        StateToQuery({ ...filter, ...newParams, pageIndex: newParams?.pageIndex ? newParams.pageIndex : 0 });
    };

    if (pageLoading) return <Loading />;

    return (
        <section className="mx-10 my-5">
            <form id="DocumentSearch" className="flex w-full">
                <DatePicker
                    onChange={(e: Date) =>
                        SETFILTER({
                            startDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-'),
                        })
                    }
                    selected={new Date(filter.startDate)}
                    label="Date de début"
                />
                <DatePicker
                    onChange={(e: Date) =>
                        SETFILTER({
                            endDate: formatDate(e, { locale: 'fr' }).split('/').reverse().join('-'),
                        })
                    }
                    selected={new Date(filter.endDate)}
                    label="Date de fin"
                />
                <Select
                    icon={BsTagFill}
                    label="Sélection de l’état"
                    name="tiers"
                    option={statusOptions}
                    defaultValue={filter.status}
                    onChange={(e: Options[]) =>
                        SETFILTER({
                            status: e,
                        })
                    }
                />
            </form>
            <Table
                nbTotalRows={totalRows}
                columns={deliveryColumn}
                data={devilveriesList}
                pageCount={pageCount}
                loading={arrayLoading}
                defaultParams={filter}
                SETFILTER={SETFILTER}
                checkBox
            />
        </section>
    );
};

const params: PARAMS = {
    pageIndex: 1,
    pageSize: 10,
    sortBy: [{ id: 'deliveryId', desc: false }],
    startDate: formatDate(getPreviousDay(), { locale: 'fr' }).split('/').reverse().join('-'),
    endDate: formatDate(new Date(), { locale: 'fr' }).split('/').reverse().join('-'),
    status: [],
    tiers: [],
    globalFilter: '',
};

export default DeliveryList;

import React, { useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

import Button from 'components/atoms/Buttons';
import Input from 'components/atoms/Form/input';

import API from 'constants/API';
import { PATCH } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import { useAuthenticateContext } from 'contexts/Authentication';

interface MyaccoutPorps {
    lastName: string;
    firstName: string;
    login: string;
    password: string;
    passwordRepeat: string;
}

const MyAccount = () => {
    const { authentication } = useAuthenticateContext();
    const { dispatch } = useContext(NotificationContext);
    const { register, handleSubmit, getValues, errors } = useForm<MyaccoutPorps>({
        defaultValues: {
            lastName: authentication.lastName,
            firstName: authentication.firstName,
            login: authentication.login,
            password: authentication.password,
            passwordRepeat: authentication.passwordRepeat,
        },
        mode: 'onChange',
    });

    const onSubmit = async (userInfo: FieldValues) => {
        const idToNumber = authentication.roleId?.map((i) => +i);
        const body = {
            Prenom: userInfo.firstName,
            password: userInfo.password,
            login: userInfo.login,
            Nom: userInfo.lastName,
            SuppressedAt: null,
            id: authentication.id,
            list_roles_id: idToNumber,
        };

        const { message, status } = await PATCH(API.USER_PROFIL, body);
        dispatch({ message, status });
    };

    return (
        <section className="container mx-auto py-2">
            <form id="MyAccountForm" onSubmit={handleSubmit(onSubmit)} className="w-full flex justify-center">
                <div className="w-1/3 px-2">
                    <Input label="Nom" name="lastName" register={register({ required: true })} variant="secondary" />
                    <Input
                        label="Prénom"
                        name="firstName"
                        register={register({ required: true })}
                        variant="secondary"
                    />
                    <Input
                        label="Identifiant"
                        name="login"
                        register={register({ required: true })}
                        variant="secondary"
                    />
                    <Input
                        label="Mot de passe"
                        name="password"
                        type="password"
                        register={register}
                        variant="secondary"
                    />
                    <Input
                        label="Répétez le mot de passe"
                        name="passwordRepeat"
                        type="password"
                        messageError="Les mots de passe ne correspondent pas"
                        register={register({
                            validate: (value) => value === getValues('password'),
                        })}
                        variant="secondary"
                        errors={errors}
                    />
                </div>
            </form>
            <div className="flex justify-center py-10">
                <Button type="submit" form="MyAccountForm" className="text-base" bold>
                    Enregistrer
                </Button>
            </div>
        </section>
    );
};

export default MyAccount;

import { useParams, useSearchParams } from 'react-router-dom';
import { FieldValues, useForm, Controller } from 'react-hook-form';
import React, { useContext, useEffect, useState } from 'react';

import Title from 'components/atoms/Title';
import Button from 'components/atoms/Buttons';
import Input from 'components/atoms/Form/input';
import Select from 'components/molecules/Inputs/Select';

import API from 'constants/API';
import { days } from 'constants/Options';
import { GET, PATCH, POST } from 'utils/Fetch';
import { NotificationContext } from 'contexts/Notifications';
import UserProps, { FormatUser, ReFormatUser, UserBuilder } from 'models/UsersBuilder/UserBuilder';
import useOptions from 'utils/Hook/GetOptions';

type Options = { value: string; label: string };

const User = () => {
    const { dispatch } = useContext(NotificationContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [user, setUser] = useState<UserProps>(UserBuilder());
    const { centreLogistique } = useOptions({
        getCentre: true,
    });
    const { register, handleSubmit, reset, getValues, errors, control, watch } = useForm();
    const { rolesOptions } = useOptions({ getRoles: true });
    const { id } = useParams<{ id: string }>();
    const [, setParams] = useSearchParams();

    const GetUser = async () => {
        const { status, message, ...response } = await GET(`${API.USER_PROFIL + id}`);
        if (!status) return dispatch({ message, status });
        const User = FormatUser(response);

        if (User.timetable) {
            const timeTable = await GetUserSchedule(User.timetable);
            reset({ day: timeTable, ...User });
            setUser(() => ({ day: timeTable, ...User }));
            return setLoading(false);
        }

        reset({ ...User });
        setUser(User);
        setLoading(false);
    };

    const GetUserSchedule = async (id: number) => {
        const { message, status, data } = await GET(API.USER_SCHEDULE + '/' + id);
        if (!data) return dispatch({ message, status });
        return data[0];
    };

    useEffect(() => {
        if (id !== '0') GetUser();
        else setLoading(false);
    }, []);

    const onSubmit = async (formInfo: FieldValues) => {
        const { day, ...info } = formInfo;

        const User = ReFormatUser({ id: id === '0' ? null : id, ...info });
        if (id === '0') {
            const { status, message, ...response } = await POST(API.USER_PROFIL, User);
            dispatch({ message, status });
            if (status) {
                if (User.list_roles_id.includes(4)) await submitTimetable(day, response.data[0].id, null);
                dispatch({ message, status });
                return setParams({ id: response.data[0].id });
            }
            return;
        }
        const { status, message } = await PATCH(API.USER_PROFIL, User);
        if (User.list_roles_id.includes(4)) await submitTimetable(day, id, user.timetable);
        dispatch({ message, status });
    };

    const submitTimetable = async (day: FieldValues, userId: any, scheduleId: null | number) => {
        if (scheduleId) {
            const body = {
                ...day,
                id: scheduleId,
            };
            const { status, message } = await PATCH(API.USER_SCHEDULE, body);
            dispatch({ message, status });
            return status;
        } else {
            const body = {
                ...day,
                id: scheduleId,
                fk_livreur: +userId,
            };
            const { status, message, data } = await POST(API.USER_SCHEDULE, body);
            setUser((curr) => ({ ...curr, timetable: data[0].id }));
            dispatch({ message, status });
            return status;
        }
    };

    const isSameRole = (a: Options, b: Options) => a.value == b.value && a.label == b.label;

    const onlyInLeft = (
        left: Options[],
        right: Options[],
        compareFunction: (arg0: Options, arg1: Options) => boolean,
    ) => left.filter((leftValue) => !right.some((rightValue) => compareFunction(leftValue, rightValue)));

    const disableInput = (list: any) => {
        if (!list) return true;
        const yesNolist = list.find((elem: { value: number | string }) => elem.value === '4' || elem.value === 4);
        if (yesNolist) return false;
        else return true;
    };

    if (loading) return <p>loading...</p>;
    return (
        <section className="container mx-auto py-2 flex flex-col items-center">
            <form
                className="w-full flex flex-col lg:flex-row lg:justify-center"
                autoComplete="off"
                id="UserForm"
                onSubmit={handleSubmit(onSubmit)}
            >
                <div className="w-full lg:w-1/3 p-5">
                    <Title text="Information utilisateur" />
                    <Input
                        label="Nom"
                        name="lastName"
                        messageError="Veuillez entrer un nom"
                        register={register({ required: true, minLength: 2 })}
                        errors={errors}
                    />
                    <Input
                        label="Prénom"
                        name="firstName"
                        messageError="Veuillez entrer un prénom"
                        register={register({ required: true, minLength: 2 })}
                        errors={errors}
                    />
                    <Input
                        label="Identifiant"
                        name="login"
                        messageError="Veuillez entrer un identifiant"
                        register={register({ required: true, minLength: 2 })}
                        errors={errors}
                    />
                    <Input
                        label="Mot de passe"
                        type="password"
                        name="password"
                        messageError="Entrer un mot de passe"
                        register={register({ required: id === '0' ? true : false, minLength: 2 })}
                        errors={errors}
                    />
                    <Input
                        label="Répétez le mot de passe"
                        type="password"
                        name="passwordRepeat"
                        messageError="Les mots de passe ne correspondent pas"
                        register={register({
                            validate: (value) => (id === '0' ? value === getValues('password') : true),
                        })}
                        errors={errors}
                    />
                    <Controller
                        control={control}
                        error={errors}
                        name="userRole"
                        rules={{
                            required: true,
                            validate: (value) => value.length >= 1,
                        }}
                        render={({ onChange, name, value }) => (
                            <>
                                <Select
                                    onChange={onChange}
                                    label="Sélection de role(s)"
                                    defaultValue={value}
                                    name={name}
                                    option={value ? onlyInLeft(rolesOptions, value, isSameRole) : rolesOptions}
                                />
                                {errors[name] && (
                                    <span className="ml-2 text-red-600 font-bold">
                                        {errors[name] && 'Séléctionner au moins un role'}
                                    </span>
                                )}
                            </>
                        )}
                    />
                    <Controller
                        control={control}
                        error={errors}
                        name="centreLogistique"
                        rules={{
                            required: true,
                            validate: (value) => value.length >= 1,
                        }}
                        render={({ onChange, name, value }) => (
                            <>
                                <Select
                                    onChange={onChange}
                                    label="Centre logistique"
                                    defaultValue={value}
                                    name={name}
                                    option={centreLogistique}
                                />
                                {errors[name] && (
                                    <span className="ml-2 text-red-600 font-bold">
                                        {errors[name] && 'Séléctionner au moins un centre'}
                                    </span>
                                )}
                            </>
                        )}
                    />
                </div>
                <div className="w-full lg:w-1/3 p-5">
                    <Title text="Horaire" />
                    {days.map((day) => {
                        return (
                            <Input
                                key={day.label}
                                label={day.label}
                                type="text"
                                name={`day.${day.label}`}
                                placeholder="Exemple: 08:00-14:00"
                                messageError="Horaire n'est pas dans le bon format"
                                register={register}
                                errors={errors}
                                disabled={disableInput(watch('userRole'))}
                            />
                        );
                    })}
                </div>
            </form>
            <Button type="submit" form="UserForm" className="text-base" bold>
                Enregistrer l&apos;utilisateur
            </Button>
        </section>
    );
};

export default User;

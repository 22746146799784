import React, { FunctionComponent } from 'react';
import { DeepMap, FieldError } from 'react-hook-form';

interface TextAreaProps {
    label?: string;
    name: string;
    register: React.LegacyRef<HTMLTextAreaElement>;
    textArea?: boolean;
    rows?: number;
    placeholder?: string;
    cols?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errors?: DeepMap<any, FieldError>;
    messageError?: string;
}

const TextArea: FunctionComponent<TextAreaProps> = ({
    label,
    placeholder,
    name,
    register,
    errors,
    messageError,
    rows = 4,
    cols = 50,
}) => {
    return (
        <div className="flex flex-col px-2 pb-2 w-full">
            <label htmlFor={name} className="flex flex-col text-sm text-gray-400 max-h-full">
                {label}
            </label>
            <textarea
                id={name}
                placeholder={placeholder}
                className="border border-gray-400 text-sm rounded-md p-2 outline-none"
                ref={register}
                name={name}
                rows={rows}
                cols={cols}
            />
            {messageError && (
                <span className="ml-2 text-red-600 font-bold">{errors && errors[name] && messageError}</span>
            )}
        </div>
    );
};

export default TextArea;

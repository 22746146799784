import { formatDate } from '@fullcalendar/react';
import FormatDate, { getPreviousDay } from 'utils/FormatDate';
import { column } from '.';

/** Change le state en chaîne de caractére pour l'url et pour les query back
 *
 * @param objectQuery
 * @returns
 */
export const QueryToString = (objectQuery: Record<string, any>): string => {
    let stringQuery = '';
    for (const [key, value] of Object.entries(objectQuery)) {
        if (Array.isArray(value)) {
            value.map((item) => {
                if (item.value !== undefined) return (stringQuery += `&${key}=${item.value}`);
                return (stringQuery += `&${key}=${item}`);
            });
        } else {
            stringQuery += `&${key}=${value}`;
        }
    }

    const _stringQuery = '?' + stringQuery.substring(1);
    return _stringQuery;
};

/**
 * Transforme le queryParams en object
 * @param urlParams
 * @returns
 */
export const QueryToState = (urlParams: string) => {
    const query: { [x: string]: any } = {};
    const params = urlParams.replace('?', '').split('&');

    for (let i = 0; i < params.length; i++) {
        const key = params[i].split('=')[0];
        const value = params[i].split('=')[1];
        if (!query[key]) {
            if (key === 'fk_etat' || key === 'fk_tiersSource') {
                query[key] = [value];
            } else {
                query[key] = value;
            }
        } else {
            if (key === 'fk_etat' || key === 'fk_tiersSource') {
                query[key] = [...query[key], value];
            } else {
                query[key] = value;
            }
        }
    }
    return query;
};

export const QueryStringFor = (
    page: 'documents' | 'delivery' | 'packageList' | 'fileHistory' | 'map',
    query?: Record<string, any>,
) => {
    let DefaultQuery = '';
    if (page === 'delivery') {
        const params = {
            numPage: 1,
            nbrElements: 10,
            columnToOrder: column['deliveryId'],
            isAscendingOrder: 1,
            startDate: formatDate(getPreviousDay(), { locale: 'fr' }).split('/').reverse().join('-'),
            endDate: formatDate(new Date(), { locale: 'fr' }).split('/').reverse().join('-'),
            search: '',
        };

        DefaultQuery = QueryToString({ ...params, ...query });
    }

    if (page === 'documents' || page === 'packageList') {
        const params = {
            numPage: 1,
            nbrElements: 10,
            columnToOrder: column['documentNumber'],
            isAscendingOrder: 1,
            startDate: formatDate(getPreviousDay(), { locale: 'fr' }).split('/').reverse().join('-'),
            endDate: formatDate(new Date(), { locale: 'fr' }).split('/').reverse().join('-'),
        };
        DefaultQuery = QueryToString({ ...params, ...query });
    }

    if (page === 'fileHistory') {
        const params = {
            numPage: 1,
            nbrElements: 10,
            columnToOrder: column['date'],
            isAscendingOrder: 0,
            search: '',
        };
        DefaultQuery = QueryToString({ ...params, ...query });
    }

    if (page === 'map') {
        const params = {
            date: FormatDate(new Date()),
        };

        DefaultQuery = QueryToString({ ...params });
    }

    return DefaultQuery;
};

export const days = [
    { value: 'monday', label: 'lundi' },
    { value: 'tuesday', label: 'mardi' },
    { value: 'wednesday', label: 'mercredi' },
    { value: 'thursday', label: 'jeudi' },
    { value: 'friday', label: 'vendredi' },
    { value: 'saturday', label: 'samedi' },
];

export const Months = ['Janv', 'Fév', 'Mars', 'Avr', 'Mai', 'Juin', 'Juill', 'Août', 'Sept', 'Oct', 'Nov', 'Dec'];

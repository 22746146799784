interface Role {
    user: string[];
    usurpation: string[];
}
export const UserControl = (roles: Role, AllowedRols: [] | string[]): boolean => {
    const test = true;
    if (!AllowedRols) return true;
    if (AllowedRols.length === 0) return true;

    if (roles.usurpation.length > 0) {
        for (let i = 0; i < AllowedRols.length; i++) {
            if (roles.usurpation.includes(AllowedRols[i])) return true;
        }
    } else {
        for (let i = 0; i < AllowedRols.length; i++) {
            if (roles.user.includes(AllowedRols[i])) return true;
        }
    }

    return test;
};

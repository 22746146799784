import FormatDate from 'utils/FormatDate';

interface StackStatisticProps {
    isDistributor: number | string;
    tsId: { id: number }[];
    excel: '0' | '1';
    startDate?: Date;
    endDate?: Date;
}
export const queries = (params: StackStatisticProps) => {
    const { isDistributor, tsId, excel, startDate, endDate } = params;
    let queries = '';
    queries += `?isGrossiste=${isDistributor}`;

    if (startDate) {
        queries += `&startDate=${FormatDate(new Date(startDate))}`;
    } else {
        const lastYear = new Date().getFullYear() - 1;
        const lastMounth = new Date().getMonth() + 1;
        queries += `&startDate=${lastYear}-${lastMounth}-01`;
    }

    if (endDate) {
        queries += `&endDate=${FormatDate(new Date(endDate))}`;
    } else {
        queries += `&endDate=${FormatDate(new Date())}`;
    }

    queries += `&exportExcel=${excel}`;
    const tiersSourceId = tsId.map((tiers: { id: number }) => `&fk_tiersSource=${tiers.id}`).join('');
    queries += tiersSourceId;
    return queries;
};

/* eslint-disable @typescript-eslint/no-explicit-any */

export interface DocumentList {
    [key: string]: string;
    statusLabel: string;
    countPackage: string;
    documentId: string;
    tiersDestId: string;
    documentNumber: string;
    tiersDestName: string;
    tiersDestAddress: string;
    tiersSourceName: string;
    arrivalTime: string;
    hasKeys: string;
    openingHours: string;
    status_color: string;
    couleurEtatKey: string;
}

export interface DeliveriesHistory {
    [key: string]: string;
    createAt: string;
    statusLabel: string;
    delivererName: string;
    status_color: string;
}

interface DeliveryProps {
    tourLabel: string;
    id: number;
    delivererName: string;
    delivererId: string;
    documentsList: DocumentList[];
    deliveriesHistory: DeliveriesHistory[];
    createAt: string;
}

export const DeliveryBuilder = (values: Partial<DeliveryProps> = {}): DeliveryProps => ({
    tourLabel: '',
    id: 0,
    delivererName: '',
    delivererId: '',
    documentsList: [],
    deliveriesHistory: [],
    createAt: '',
    ...values,
});

export const FormatDelivery = ({ data }: any): DeliveryProps => {
    const array = data.map((data: any) => {
        const _documentsList = data.list_documents.map(
            (data: any): DocumentList => {
                return {
                    statusLabel: data.Libelle_fk_etat,
                    countPackage: data.countColis,
                    documentId: data.fk_document,
                    tiersDestId: data.fk_tiersDestination,
                    documentNumber: data.numero_document,
                    tiersDestName: data.raisonSocialeTiersDestination,
                    tiersSourceName: data.raisonSocialeTiersSource,
                    arrivalTime: data.arrivalTime,
                    hasKeys: data.hasKeys,
                    tiersDestAddress: data.concatTiersDestinationAdress,
                    openingHours: data.openingHours,
                    status_color: data.couleur,
                    numeroKey: data.numeroKey,
                    etatKey: data.etatKey,
                    couleurEtatKey: data.couleurEtatKey,
                };
            },
        );

        const _deliveriesHistory = data.history_livraison.map(
            (data: any): DeliveriesHistory => {
                return {
                    createAt: data.lastHistoryEtat,
                    statusLabel: data.Libelle_fk_etat,
                    delivererName: data.concatNomPrenom,
                    status_color: data.couleur,
                };
            },
        );

        return {
            tourLabel: data.LibelleTournee,
            id: data.id,
            delivererName: data.concatNomPrenom,
            delivererId: data.fk_livreur,
            documentsList: _documentsList,
            deliveriesHistory: _deliveriesHistory,
            createAt: data.createdAt,
        };
    });

    return array[0];
};

export default DeliveryProps;

import React, { FunctionComponent, Fragment, useEffect, useState, useContext } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import Table from 'components/organisms/Table/ReactTable/SyncTable';

import { LinkOutlined } from '@ant-design/icons';

import Routes from 'constants/Routes';
import DownShift from 'components/atoms/Form/downShift';
import API from 'constants/API';
import { GET, POST } from 'utils/Fetch';
import { Destination } from 'models/CompanyBuilder/CompaniesList';
import { NotificationContext } from 'contexts/Notifications';
import { Modal, Button } from 'antd';
import { Link } from 'react-router-dom';

interface DestinatairesSourcesProps {
    id: string;
    identifiant: string;
    typeId: string;
}

const DestinatairesSources: FunctionComponent<DestinatairesSourcesProps> = ({ id, typeId, identifiant }) => {
    const { dispatch } = useContext(NotificationContext);
    const [listeDesSources, setListeDesSources] = useState<Destination[]>([]);
    const [listeDesSourcesSelect, setListeDesSourcesSelect] = useState<any>([]);
    const [selectedTiers, setSelectedTiers] = useState<any>();
    const [open, setOpen] = useState<{ open: boolean; identifiant?: string }>({ open: false });

    const GetTiersList = async () => {
        const option = typeId === '1' ? 'source' : 'destinataire';
        let response = await GET(`${API.LIST_TIERS_DES_SOURCES}?type=${option}&id=${id}`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        if (option === 'destinataire') setListeDesSources(response.data.destinataire);
        if (option === 'source') setListeDesSources(response.data);
        response = await GET(`${API.LIST_TIERS_DES_SOURCES}?type=source&id=all`);
        if (!response.status) return dispatch({ status: response.status, message: response.message });
        const itemDownShift: any[] = [];
        for (let index = 0; index < response.data.length; index++) {
            itemDownShift.push({
                label: `${response.data[index].RaisonSociale} ${response.data[index].Identifiant}`,
                address: response.data[index].adresseComplete,
                login: response.data[index].Identifiant,
                value: response.data[index],
            });
        }
        setListeDesSourcesSelect(itemDownShift);
    };
    const mouvementTiers = async () => {
        const data = {
            identifiant: open.identifiant,
            newDestinataire: selectedTiers.tiersId,
            idSource: listeDesSources[0].fk_tiersSource,
        };
        const response = await POST(API.MOUVEMENT_TIERS_SOURCE, data);
        setOpen({ open: false });
        GetTiersList();
        setSelectedTiers(undefined);
        return dispatch({ status: response.status, message: response.message });
    };
    useEffect(() => {
        GetTiersList();
    }, []);

    const MailTo = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => (
        <Fragment>
            <span className="flex justify-between">
                <a style={{ textDecoration: 'none', color: '#04336d' }} href={`mailto:${props.value}`}>
                    {props.value}
                </a>
            </span>
        </Fragment>
    );

    const viewHoraire = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => {
        let value = [props.value];
        if (props.value) value = props.value.split(';');
        return (
            <Fragment>
                {value.map((row, i) => (
                    <p key={i}>
                        {row}
                        <br />
                    </p>
                ))}
            </Fragment>
        );
    };

    const LocationCell = (props: { column: { Header: string }; value: string; row: { original: { id: string } } }) => {
        return (
            <Fragment>
                <span className="flex justify-between">
                    <span>
                        <span className="text-sm pl-2">{props.value}</span>
                    </span>

                    <a
                        className="text-sm text-purple-900 hover:underline"
                        target="_blank"
                        href={`https://www.google.com/maps/search/?api=1&query=${props.value}`}
                        rel="noreferrer"
                    >
                        <FaMapMarkedAlt className="text-lg" />
                    </a>
                </span>
            </Fragment>
        );
    };

    const columns = [
        {
            Header: 'Identifiant',
            accessor: 'Identifiant',
            className: 'text-left border border-gray-200  px-2 w-1/12',

            Cell: (props: {
                row: { original: { tsRaisonSociale: string; Identifiant: string; tdId: string; tsId: string } };
            }) => (
                <Fragment>
                    <Link to={`${Routes.COMPANY}/${props.row.original.tdId}`} className="w-full" target="_blank">
                        <p className="text-sm text-purple-900 hover:underline">{props.row.original.Identifiant}</p>
                    </Link>
                </Fragment>
            ),
        },
        {
            Header: 'Raison Social destinataire',
            accessor: 'RaisonSociale',
            className: 'text-left border border-gray-200  px-2  w-2/12',
        },
        {
            Header: 'Mail destinataire',
            accessor: 'email',
            Cell: MailTo,
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },

        {
            Header: 'Ville destinataire',
            accessor: 'adresseComplete',
            className: 'text-left border border-gray-200  px-2 w-3/12',
            Cell: LocationCell,
        },
        {
            Header: 'Commentaire destinataire',
            accessor: 'commentaire',
            className: 'text-left border border-gray-200  px-2 w-1/12',
        },
        {
            Header: 'Horaire destinataire',
            accessor: 'openingHours',
            Cell: viewHoraire,
            className: 'text-left border border-gray-200  px-2 w-2/12',
        },
    ];
    if (typeId !== '1')
        columns.unshift(
            {
                Header: ' ',
                accessor: '',
                className: 'text-left ',
                Cell: (props: { row: { original: { Identifiant: string } } }) => (
                    <LinkOutlined
                        placeholder="tes"
                        className="mr-2 !text-blue-700 "
                        onClick={() => setOpen({ open: true, identifiant: props.row.original.Identifiant })}
                    />
                ),
            },
            {
                Header: 'Source',
                accessor: 'tsRaisonSociale',
                className: 'text-left border border-gray-200  px-2 w-1/12',
                Cell: (props: {
                    row: { original: { tsRaisonSociale: string; tsId: string; Identifiant: string; tdId: string } };
                }) => (
                    <Fragment>
                        <Link to={`${Routes.COMPANY}/${props.row.original.tsId}`} className="w-full">
                            <p className="text-sm text-purple-900 hover:underline">
                                {props.row.original.tsRaisonSociale}
                            </p>
                        </Link>
                    </Fragment>
                ),
            },
        );
    return (
        <section className="mx-10 my-5 w-[100%]">
            <Modal
                closable={false}
                open={open.open}
                footer={[
                    <Button
                        danger
                        key="back"
                        onClick={() => {
                            setSelectedTiers(undefined);
                            setOpen({ open: false });
                        }}
                    >
                        Retour
                    </Button>,
                    <Button
                        type="primary"
                        className=" !border-blue-700 !bg-blue-700"
                        key="back"
                        onClick={() => mouvementTiers()}
                    >
                        valider
                    </Button>,
                ]}
            >
                <>
                    <span>Associer ce tier à une destinaton</span>
                    <div className="flex items-center mb-5 mt-2">
                        <div className="w-[90%] mr-10">
                            {listeDesSourcesSelect.length !== 0 && (
                                <DownShift
                                    placeholder="Choisir le tiers"
                                    items={listeDesSourcesSelect}
                                    onChange={setSelectedTiers}
                                />
                            )}
                        </div>
                    </div>
                </>
            </Modal>
            {listeDesSources.length !== 0 ? (
                <Table columns={columns} data={listeDesSources} />
            ) : (
                <p>Pas de données dans la base de données</p>
            )}
        </section>
    );
};

export default DestinatairesSources;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ListOfUserProps {
    id: string;
    login: string;
    name: string;
    rolesId: string;
    rolesLabel: string;
    centresLabel: string;
}

export const UsersListBuilder = (values: Partial<ListOfUserProps> = {}): ListOfUserProps[] => [
    {
        id: '',
        login: '',
        name: '',
        rolesId: '',
        rolesLabel: '',
        centresLabel: '',
        ...values,
    },
];

export const FormatUserList = ({ data }: any): ListOfUserProps[] => {
    const array = data.map(
        (data: any): ListOfUserProps => {
            return {
                id: data.id,
                login: data.Login,
                name: data.concatNomPrenom,
                rolesId: data.concatRoleId,
                rolesLabel: data.concatRoleLibelle,
                centresLabel: data.concatCentreLogistique,
            };
        },
    );

    return array;
};

export default ListOfUserProps;

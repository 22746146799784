export interface PackagesListProps {
    packageId: string;
    packageNumber: string;
    documentId: string;
    documentNumber: string;
    createAt: string;
    statusLabel: string;
    statusPackage_date: string;
    latitude: string;
    longitude: string;
    statusPackage_label: string;
    color: string;
    tiersSourceId: string;
    tiersSourceName: string;
    tiersDestId: string;
    tiersDestName: string;
    packageTypeId: string;
    packageTypeLabel: string;
    [key: string]: string;
}

export const PackagesListBuilder = (values: Partial<PackagesListProps> = {}): PackagesListProps[] => [
    {
        packageId: '',
        packageNumber: '',
        documentId: '',
        documentNumber: '',
        createAt: '',
        statusLabel: '',
        statusPackage_date: '',
        latitude: '',
        longitude: '',
        statusPackage_label: '',
        color: '',
        tiersSourceId: '',
        tiersSourceName: '',
        tiersDestId: '',
        tiersDestName: '',
        tour_id: '',
        packageTypeId: '',
        packageTypeLabel: '',
        tour_label: '',
        deliveryId: '',
        deliveryman_name: '',
        ...values,
    },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormatPackagesList = ({ data }: any): PackagesListProps[] => {
    const array = data.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (data: any): PackagesListProps => {
            return {
                packageId: data.id_colis,
                packageNumber: data.numero_colis,
                documentId: data.id_document,
                documentNumber: data.numero_document,
                createAt: data.lastHistoryEtat,
                tiersSourceId: data.fk_tiersSource,
                tiersSourceName: data.raisonSocialeTiersSource,
                tiersDestId: data.fk_tiersDestination,
                tiersDestName: data.raisonSocialeTiersDestination,
                latitude: data.latitude,
                longitude: data.longitude,
                color: data.couleur,
                //A voir si sa existe encore
                statusLabel: data.Libelle_fk_etat,
                deliveryId: data.fk_livraison,
                statusPackage_date: data.date_etatColis,
                statusPackage_label: data.label_etatColis,
                packageTypeId: data.id_colisType,
                packageTypeLabel: data.label_colisType,
            };
        },
    );

    return array;
};

export default PackagesListProps;

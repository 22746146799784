/* eslint-disable @typescript-eslint/no-explicit-any */
export interface HistoryProps {
    [key: string]: string | string[];
    status_id: string;
    status_label: string;
    status_description: string;
    status_color: string;
    status_forType: string;
    counterColis: string;
    counterDocument: string;
    counterLivraison: string;
    libelleColis: string;
    libelleDocuments: string;
    fk_etat: string;
    ForType: string[];
}

export const homeListBuilder = (values: Partial<HistoryProps> = {}): HistoryProps[] => [
    {
        status_id: '',
        status_label: '',
        status_description: '',
        status_color: '',
        status_forType: '',
        ForType: [],
        fk_etat: '',
        counterColis: '',
        counterDocument: '',
        counterLivraison: '',
        libelleColis: '',
        libelleDocuments: '',
        ...values,
    },
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formatHistory = ({ data }: any): HistoryProps[] => {
    const array = data.map(
        (data: any): HistoryProps => {
            return {
                status_id: data.id,
                status_label: data.Libelle,
                status_description: data.description,
                status_color: data.couleur,
                status_forType: data.ForType,
                counterColis: data.counterColis,
                counterDocument: data.counterDocument,
                counterLivraison: data.counterLivraison,
                libelleColis: data.libelleColis,
                libelleDocuments: data.libelleDocuments,
                fk_etat: data.id,
                ForType: data.ForType,
            };
        },
    );

    return array;
};

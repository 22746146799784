import React, { FunctionComponent, useState } from 'react';
import { useCombobox } from 'downshift';
import classNames from 'classnames';

interface DownShiftProps {
    label?: string;
    placeholder?: string;
    items: {
        label: string;
        value: string;
        identifiant?: string;
        subLabel?: string;
        address?: string;
        login?: string;
    }[];
    defaultValue?: {
        label: string;
        value: string;
        identifiant?: string;
        subLabel?: string;
        address?: string;
        login?: string;
    };
    onChange: any;
    selectDisabled?: boolean;
}
const DropdownCombobox: FunctionComponent<DownShiftProps> = ({
    items,
    onChange,
    defaultValue,
    label,
    placeholder,
    selectDisabled,
}) => {
    const [inputItems, setInputItems] = useState(items);
    const {
        isOpen,
        openMenu,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
    } = useCombobox<{
        label: string;
        value: string;
        identifiant?: string;
        subLabel?: string;
        address?: string;
        login?: string;
    }>({
        items: inputItems,
        defaultSelectedItem: defaultValue,
        itemToString: (item) => (item ? item.label : ''),
        onInputValueChange: ({ inputValue = '' }) => {
            const filteredItems = items.filter((item) => {
                return item.label.toLowerCase().includes(inputValue.toLowerCase());
            });
            setInputItems(filteredItems);
        },
        onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem?.value),
    });
    return (
        <div className="relative flex flex-col justify-center items-start px-2 mb-2 ">
            <label className="pb-1 flex flex-col text-sm text-gray-400 max-h-full" {...getLabelProps()}>
                {label}
            </label>
            <div className="flex justify-between w-full" {...getComboboxProps()}>
                <input
                    onFocus={() => openMenu()}
                    className={classNames(
                        'w-full rounded-l-sm focus:outline-none focus:ring-1 border border-gray-300 focus:ring-purple-700 focus:border-purple-700 h-9 p-2',
                        {
                            'bg-gray-200': selectDisabled,
                        },
                    )}
                    placeholder={placeholder}
                    {...getInputProps()}
                    disabled={selectDisabled}
                />
                <button
                    className="flex rounded-r-sm justify-center items-center px-2 border bg-gray-200 border-gray-300 focus:bg-violet-50 h-9"
                    type="button"
                    {...getToggleButtonProps()}
                    aria-label="toggle menu"
                    disabled={selectDisabled}
                >
                    &#8595;
                </button>
            </div>

            <ul
                className={classNames({
                    'absolute top-16 w-full bg-white overflow-scroll z-50 max-h-80 border border-violet-800': isOpen,
                })}
                {...getMenuProps()}
            >
                {isOpen &&
                    inputItems.map((item, index) => (
                        <li
                            className="overflow-x-hidden p-1 bg-purple-600"
                            key={`${item}${index}`}
                            {...getItemProps({
                                key: index,
                                index,
                                item,
                                style: {
                                    backgroundColor: highlightedIndex === index ? 'rgba(109, 40, 217)' : 'white',
                                    color: highlightedIndex === index ? 'white' : 'black',
                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                },
                            })}
                        >
                            <div className="flex flex-col">
                                <span>
                                    {item.label}
                                    {item.identifiant && (
                                        <span className="text-gray-400 text-xs italic ml-1">({item.identifiant})</span>
                                    )}
                                </span>
                                {item.identifiant}
                                <div className="flex">
                                    <span className=" text-[0.8em] ml-[5em]">{`Identifiant: ${item.login}`}</span>
                                    <span className=" text-[0.8em] ml-[10em]">{item.address}</span>
                                </div>

                                <span className="text-gray-400 text-xs italic">{item.subLabel && item.subLabel}</span>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

export default DropdownCombobox;

import React, { Fragment } from 'react';
import Routes from 'constants/Routes';
import { Link } from 'react-router-dom';
import LocationCell from 'components/atoms/LocationCell/index';

const Sources = (props: { value: string; row: { original: { tiersSourceId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersSourceId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const Dest = (props: { value: string; row: { original: { tiersDestId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.COMPANY}/${props.row.original.tiersDestId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const Document = (props: { value: string; row: { original: { documentId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.DOCUMENTS}/${props.row.original.documentId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

const Package = (props: { value: string; row: { original: { packageId: string } } }) => (
    <Fragment>
        <Link to={`${Routes.PACKAGES}/${props.row.original.packageId}`}>
            <p className="text-sm w-5/6 text-purple-900 hover:underline">{props.value}</p>
        </Link>
    </Fragment>
);

export const colisColumns = [
    {
        Header: 'Tiers source',
        accessor: 'tiersSourceName',
        Cell: Sources,
    },
    {
        Header: 'Tiers destination',
        accessor: 'tiersDestName',
        Cell: Dest,
    },

    {
        Header: 'Document',
        accessor: 'documentNumber',
        Cell: Document,
    },
    {
        Header: 'Numéro',
        accessor: 'packageNumber',
        Cell: Package,
    },
    {
        Header: 'Etat',
        accessor: 'statusLabel',
        Cell: LocationCell,
    },
];

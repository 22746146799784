/* eslint-disable @typescript-eslint/no-explicit-any */
export interface CompanySource {
    label: string;
    value: string;
}

export const FormatOptionsCompany = (data: any) => {
    const company = data.map((company: any) => {
        return {
            label: company.RaisonSocial,
            value: company.id,
        };
    });

    return company;
};

export const FormatOptMotive = ({ data }: any) =>
    data.map((motive: any) => ({
        label: motive.libelle,
        value: motive.id,
    }));

import { Link } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import Button from 'components/atoms/Buttons';
import { UserControl } from 'utils/UserControl';
import { useAuthenticateContext } from 'contexts/Authentication';

interface OptionsProps {
    value: string;
    label: string;
    userRole: string[] | [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    subOption?: any[];
}

interface SelectsProps {
    label?: string;
    options: OptionsProps[];
    LogOut?: () => void;
}

const Dropdown: FunctionComponent<SelectsProps> = ({ label, options, LogOut = () => {} }) => {
    const [dropdownPopoverShow, setDropdownPopoverShow] = useState<boolean>(false);
    const { authentication } = useAuthenticateContext();

    const ref: React.ForwardedRef<HTMLDivElement> = useRef(null);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const handleClickOutside = (event: { target: any }) => {
            if (ref.current) {
                if (!ref.current.contains(event.target)) {
                    setDropdownPopoverShow(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
    }, [ref]);

    return (
        <div ref={ref} className="px-4">
            <div className="relative inline-flex align-middle">
                <Button
                    className="w-40"
                    variant="primary"
                    onClick={() => setDropdownPopoverShow(!dropdownPopoverShow)}
                    bold
                >
                    {label}
                </Button>
                {dropdownPopoverShow && (
                    <div
                        className="bg-white absolute top-9 -right-0 text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
                        style={{ minWidth: '12rem' }}
                    >
                        {options.map((option, index) => {
                            if (UserControl(authentication.roleLabel, option.userRole) === false) return;
                            return (
                                <Link
                                    key={index}
                                    className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-purple-800 hover:bg-purple-700 hover:text-white"
                                    to={option.value}
                                >
                                    {option.label}
                                </Link>
                            );
                        })}
                        <div className="h-0 my-2 border border-solid border-t-0 border-purple-800 opacity-25" />
                        <Button variant="dropdown" onClick={() => LogOut()}>
                            Se Déconnecter
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;

import API from 'constants/API';
import Routes from 'constants/Routes';
import Cookies from 'js-cookie';

const factory = (method: string) => async (
    url: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    body?: string | Record<string, any>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    // ): Promise<{ nbTotalRows: number; message: string; status: boolean; data: any | any[] }> => {
) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    const options: RequestInit = {
        method,
        headers,
    };

    const token = Cookies.get('session');
    if (!token && url !== API.LOGIN) return (window.location.href = Routes.LOGIN);

    if (token) headers.append('Authorization', `Bearer ${token}`);

    if (body) options.body = JSON.stringify(body);

    const request = await fetch(url, options);
    if ('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' === request.headers.get('Content-Type')) {
        return request.blob();
    }
    const response = await request.json();

    return response;
};

export const GET = factory('GET');
export const PATCH = factory('PATCH');
export const POST = factory('POST');
export const DELETE = factory('DELETE');

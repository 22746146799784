import Cookies from 'js-cookie';

const factory = (method: string) => async (
    url: string,
    body: FileList,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<{ message: string; status: boolean; data: any | any[] }> => {
    const headers = new Headers();
    const token = Cookies.get('session');

    headers.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('file', body[0]);
    formData.append('nom', body[0].name);
    const options = {
        method,
        headers,
        body: formData,
    };

    const request = await fetch(url, options);
    const response = await request.json();

    return response;
};

export const UPLOAD = factory('POST');

export interface Options {
    label: string;
    value: string;
}

export interface DefaultValue {
    endDate: Date;
    status: Options[];
    startDate: Date;
    tiers: Options[];
}

export interface SimpleParams {
    pageSize: number;
    pageIndex: number;
    globalFilter: string;
    columnId: string;
    sortBy: string;
    sortThisColumn: string;
    endDate: null;
    status: null;
    startDate: null;
    tiers: null;
}
export interface Params extends DefaultValue {
    [key: string]: string | number | Date | Options[];
    pageSize: number;
    pageIndex: number;
    globalFilter: string;
    columnId: string;
    sortBy: string;
    sortThisColumn: string;
}

export const ParamsParamter: Params = {
    pageSize: 0,
    pageIndex: 0,
    globalFilter: '',
    sortThisColumn: '',
    columnId: '',
    sortBy: '',
    endDate: new Date(),
    status: [],
    startDate: new Date(),
    tiers: [],
};

interface ColumnProps {
    [key: string]: string;
}

export interface BackParams {
    fk_etat: Options[];
    endDate: string;
    startDate: string;
    numPage: number;
    nbrElements: number;
    columnToOrder: string;
    isAscendingOrder: 0 | 1;
    search: string;
}

export interface BackAllParams extends BackParams {
    fk_tiersSource: Options[];
}

export type TableParams = {
    pageIndex: number;
    pageSize: number;
    sortBy: {
        id: string;
        desc: boolean;
    }[];
    globalFilter: string;
};
export interface PARAMS extends TableParams {
    endDate: string;
    startDate: string;
    status: Options[];
    tiers: Options[];
}

export const column: ColumnProps = {
    tiersDestName: 'raisonSocialeTiersDestination',
    tiersSourceName: 'raisonSocialeTiersSource',
    documentNumber: 'numero_document',
    countPackage: 'countColis',
    packageNumber: 'numero_colis',
    statusLabel: 'lastHistoryEtat',
    createAt: 'lastHistoryEtat',
    deliveryId: 'id_livraison',
    tourStatus: 'Libelle_fk_etat',
    delivererName: 'concatNomPrenom',
    tourLabel: 'LibelleTournee',
    fileName: 'filename',
    tiersName: 'RaisonSocialeTiers',
    date: 'dateTraitement',
};

export const APItoFront: ColumnProps = {
    raisonSocialeTiersSource: 'tiersSourceName',
    raisonSocialeTiersDestination: 'tiersDestName',
    Libelle_fk_etat: 'tourStatus',

    numero_document: 'documentNumber',
    countColis: 'countPackage',
    numero_colis: 'packageNumber',
    lastHistoryEtat: 'statusLabel',
    id_livraison: 'deliveryId',
    concatNomPrenom: 'delivererName',
    LibelleTournee: 'tourLabel',
    filename: 'fileName',
    RaisonSocialeTiers: 'tiersName',
    dateTraitement: 'date',
};

const formatDate = (date: Date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const CreateQueries = (params: Params | SimpleParams) => {
    const {
        status,
        tiers,
        endDate: _endDate,
        startDate: _startDate,
        pageIndex,
        pageSize,
        sortThisColumn,
        sortBy,
        globalFilter,
    } = params;

    let queries = '';
    let startDate;
    if (_startDate) {
        startDate = `?startDate=${formatDate(_startDate)}`;
        queries = startDate;
    }
    let endDate;
    if (_endDate) {
        endDate = `&endDate=${formatDate(_endDate)}`;
        queries += endDate;
    }
    let fk_etats;
    if (status) {
        fk_etats = status.map((element) => `&fk_etat=${element.value}`).join('');
        queries += fk_etats;
    }
    let fk_tiersSource;
    if (tiers) {
        fk_tiersSource = tiers.map((element) => `&fk_tiersSource=${element.value}`).join('');
        queries += fk_tiersSource;
    }
    let numPages;
    if (!_startDate) {
        numPages = `?numPage=${pageIndex}`;
    } else {
        numPages = `&numPage=${pageIndex}`;
    }

    const nbrElements = `&nbrElements=${pageSize}`;
    const isAscendingOrder = `&isAscendingOrder=${sortBy}`;
    const columnToOrder = sortThisColumn ? `&columnToOrder=${column[`${sortThisColumn}`]}` : '';
    const search = globalFilter ? `&search=${globalFilter}` : '';

    queries += numPages + nbrElements + columnToOrder + isAscendingOrder;
    if (globalFilter) queries += search;

    return queries;
};

export default CreateQueries;
